import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'

import { deleteDraftPick, draftPlayer } from '../../clients/proxyClient'
import { DraftConfig, DraftOrder, DraftOrigin, DraftPicks } from '../../models/draft'
import {
  ClaimDraftSlotPayload,
  DeleteDraftPickFulfilledPayload,
  DequeuePlayerPayload,
  DraftPlayerThunkPayload,
  QueuePlayerPayload,
  UpdateCurrentPositionForRanksPayload,
  UpdateQueueOrderPayload,
} from '../models/draftArenaActions'
import { getDraftDetailsThunk, getSleeperDraftDetailsThunk, getSleeperDraftPicksThunk } from '../thunk/draftArenaThunk'
import { logoutUserThunk } from '../thunk/sessionThunk'
import { SLEEPER_TO_FANTASY_DATA_PLAYER_ID } from '../../constant'
import { getCurrRoundPick, getCurrTeam, getNewRound } from '../../util/pickStateHelper'

export type PickState = {
  overall: number
  currTeam: number
  currRound: number
  roundPick: number
}

type DraftState = {
  draftId: string
  draftConfig?: DraftConfig
  teams: DraftOrder
  pickState: PickState
  picks: DraftPicks
  queue: number[]
  userDraftSlot: number
  currentPositionForRanks: string
}

const initialState: DraftState = {
  draftId: '',
  pickState: {
    overall: 1,
    currTeam: 1,
    currRound: 1,
    roundPick: 1
  },
  teams: {},
  picks: {},
  queue: [],
  userDraftSlot: 1,
  currentPositionForRanks: 'OVR'
}

export const draftPlayerThunk = createAsyncThunk(
  'draftPlayer',
  async ({ playerId }: DraftPlayerThunkPayload, { getState }): Promise<void> => {
    const state = getState() as { draftArena: DraftState }
    const { draftId, pickState } = state.draftArena
    // Decrementing overall by 1 since the 'pending' reducer runs and increments overall before this gets run
    await draftPlayer(playerId, draftId, pickState.overall - 1)
  }
)

export const deleteDraftPickThunk = createAsyncThunk(
  'deleteDraftPick',
  async (_, { getState }): Promise<DeleteDraftPickFulfilledPayload> => {
    const state = getState() as { draftArena: DraftState }
    const { draftId, pickState, picks } = state.draftArena
    await deleteDraftPick(draftId, pickState.overall - 1)
    return { playerId: picks[pickState.overall - 1] }
  }
)

export const draftArenaSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    queuePlayer: (state, action: PayloadAction<QueuePlayerPayload>) => {
      const { playerId } = action.payload
      if (state.queue.includes(playerId)) {
        return
      }
      state.queue.push(playerId)
    },
    dequeuePlayer: (state, action: PayloadAction<DequeuePlayerPayload>) => {
      const { playerId } = action.payload
      removePlayerFromQueue(state, playerId)
    },
    updateQueueOrder: (state, action: PayloadAction<UpdateQueueOrderPayload>) => {
      const { oldIndex, newIndex } = action.payload
      const [removed] = state.queue.splice(oldIndex, 1)
      state.queue.splice(newIndex, 0, removed)
    },
    updateCurrentPositionForRanks: (state, action: PayloadAction<UpdateCurrentPositionForRanksPayload>) => {
      state.currentPositionForRanks = action.payload.position
    },
    claimDraftSlot: (state, action: PayloadAction<ClaimDraftSlotPayload>) => {
      state.userDraftSlot = action.payload.draftSlot
    },
    unloadDraft: () => initialState
  },
  extraReducers: reducer =>
    reducer
      .addCase(getSleeperDraftPicksThunk.fulfilled, (state, action) => {
        let highestPick = 0
        state.picks = action.payload.reduce((acc, pick) => {
          const domainPlayerId = SLEEPER_TO_FANTASY_DATA_PLAYER_ID[pick.player_id] ?? 0
          removePlayerFromQueue(state, domainPlayerId)
          highestPick = Math.max(highestPick, pick.pick_no)

          return {
            ...acc,
            [pick.pick_no]: domainPlayerId
          }
        }, {})

        const numDrafters = state.draftConfig?.numDrafters as number
        const newOverall = highestPick + 1
        updatePickState(state.pickState, newOverall, numDrafters)
      })
      .addCase(draftPlayerThunk.pending, (state, action) => {
        // Optimistically update the draft state assuming call succeeds
        const { playerId } = action.meta.arg
        const numDrafters = state.draftConfig?.numDrafters as number
        const newOverall = state.pickState.overall + 1
        state.picks[state.pickState.overall] = playerId
        removePlayerFromQueue(state, playerId)
        updatePickState(state.pickState, newOverall, numDrafters)
      })
      .addCase(deleteDraftPickThunk.fulfilled, (state) => {
        if (!state.draftConfig) {
          return
        }
        const numDrafters = state.draftConfig.numDrafters
        const newOverall = state.pickState.overall - 1
        updatePickState(state.pickState, newOverall, numDrafters)
        delete state.picks[state.pickState.overall]
      })
      .addMatcher(
        isAnyOf(
          logoutUserThunk.fulfilled,
          getDraftDetailsThunk.pending,
          getSleeperDraftDetailsThunk.pending
        ), () => initialState
      )
      .addMatcher(
        isAnyOf(
          getDraftDetailsThunk.fulfilled, 
          getSleeperDraftDetailsThunk.fulfilled
        ), (state, action) => {
          const { draftId } = action.meta.arg
          const { draftDetails } = action.payload
          const picks = draftDetails.picks

          state.draftId = draftId
          state.draftConfig = action.payload.draftDetails.config
          state.teams = draftDetails.draftOrder
          state.picks = picks

          const numDrafters = state.draftConfig.numDrafters as number
          let maxPick = 0
          Object.keys(picks).forEach(pickNumber => maxPick = Math.max(maxPick, parseInt(pickNumber)))
          const overall = maxPick + 1
          state.pickState.overall = overall
          state.pickState.currRound = getNewRound(overall, numDrafters)
          state.pickState.currTeam = getCurrTeam(overall, numDrafters)
          state.pickState.roundPick = getCurrRoundPick(overall, numDrafters)

          if (draftDetails.draftOrigin === DraftOrigin.AdjustTheRanks) {
            const draftSlotString = Object.keys(draftDetails.draftOrder).find(key => draftDetails.draftOrder[key as any as number] !== 'CPU') || '1'
            const draftSlot = parseInt(draftSlotString)
            state.userDraftSlot = draftSlot
          }
        }
      )
})

export const {
  queuePlayer,
  dequeuePlayer,
  updateQueueOrder,
  unloadDraft,
  updateCurrentPositionForRanks,
  claimDraftSlot,
} = draftArenaSlice.actions

export default draftArenaSlice.reducer


export const removePlayerFromQueue = (state: DraftState, playerId: number) =>
  state.queue = state.queue.filter(p => p !== playerId)

const updatePickState = (pickState: PickState, newOverall: number, numDrafters: number) => {
  const newRound = getNewRound(newOverall, numDrafters)
  pickState.overall = newOverall
  pickState.currTeam = getCurrTeam(newOverall, numDrafters)
  pickState.roundPick = getCurrRoundPick(newOverall, numDrafters)
  pickState.currRound = newRound
}