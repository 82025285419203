import { FC, FormEvent, useState } from 'react'
import styled from 'styled-components'
import { DARK_MODE_BG, DARK_MODE_CTA, DARK_MODE_TEXT_PRIMARY } from '../../../styles/variables'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${DARK_MODE_BG};
`

const FormContainer = styled.div`
  max-width: 410px;
  margin: 0 auto;
  padding-top: 210px;
`

const Header = styled.h3`
  color: ${DARK_MODE_TEXT_PRIMARY};
  margin-bottom: 7px;
`

const Form = styled.div`
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  flex-basis: 75%;
  padding-left: 10px;
  border-radius: 4px;
  border: none;
`

const GoButton = styled.button`
  background: ${DARK_MODE_CTA};
  color: ${DARK_MODE_TEXT_PRIMARY};
  height: 47px;
  border-radius: 25px;
  font-size: .9rem;
  flex-basis: 20%;
`

export const CreateDraftFromSleeper: FC = () => {
  const history = useHistory()
  const [draftId, setDraftId] = useState('')

  const onCreateDraftFromIdClick = () => {
    console.log(draftId)
    if (draftId.includes('/')) {
      const id = draftId.split('/').at(-1)?.split('?')[0];
      history.push(`/drafts/${id}?origin=sleeper`)
    } else {
      history.push(`/drafts/${draftId}?origin=sleeper`)
    }
  }

  return (
    <Container>
      <FormContainer>
        <Header>Create from Sleeper draft ID</Header>
        <Form>
          <Input 
            type='text' 
            placeholder='Enter Sleeper draft ID'
            value={draftId}
            onChange={(e: FormEvent<HTMLInputElement>) => setDraftId(e.currentTarget.value)}/>
          <GoButton onClick={onCreateDraftFromIdClick}>Go</GoButton>
        </Form>
      </FormContainer>
    </Container>
  )
}