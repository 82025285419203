import { FC } from 'react'
import styled from 'styled-components'
import { DARK_MODE_BUTTON, DARK_MODE_BUTTON_ACTIVE, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY } from '../../../styles/variables'

type PositionButtonProps = {
  isActive: boolean
  position: string
  onClick: () => void
}

const Button = styled.button<{ $isActive: boolean }>`
  border-radius: 40px;
  height: 40px;
  width: 40px;
  background: ${ props => props.$isActive ? DARK_MODE_BUTTON_ACTIVE : DARK_MODE_BUTTON };
  color: ${ props => props.$isActive ? DARK_MODE_TEXT_PRIMARY : DARK_MODE_TEXT_SECONDARY};
  padding: 0;
`

export const PositionButton: FC<PositionButtonProps> = ({ isActive, position, onClick }: PositionButtonProps) => {
  return (
    <Button 
      $isActive={isActive} 
      onClick={onClick}
      >
        {position}
    </Button>
  )
}