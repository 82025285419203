import { FC } from 'react'
import { PickState } from '../../../store/slices/draftArenaSlice'
import styled from 'styled-components'
import { DARK_MODE_BUTTON, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY } from '../../../styles/variables'
import { useAppSelector } from '../../../store/hooks'
import { picksUntilNextSelector } from '../../../store/selectors/draftArenaSelector'

type DraftToolbarProps = {
  pickState: PickState
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 12px 5px;
  color: ${DARK_MODE_TEXT_PRIMARY};
`
const ContentBlockContainer = styled.div`
  background: ${DARK_MODE_BUTTON};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
  padding: 0 5px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const ContentBlock = styled.div`
  text-align: center;
  background: ${DARK_MODE_BUTTON};
  padding: 6px;
  border-radius: 3px;
  margin: 0 5px;
  min-width: 60px;
`

const ContentValue = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
`

const ContentDescription = styled.div`
  color: ${DARK_MODE_TEXT_SECONDARY};
  font-size: .8rem;
`

export const DraftToolbar: FC<DraftToolbarProps> = ({
  pickState
}) => {
  const pickUntilNext = useAppSelector(picksUntilNextSelector)
  return (
    <Container>
      <ContentBlockContainer>
        <ContentBlock>
          <ContentValue>{pickState.overall}</ContentValue>
          <ContentDescription>Overall</ContentDescription>
        </ContentBlock>
        <ContentBlock>
          <ContentValue>{pickState.currRound}</ContentValue>
          <ContentDescription>Round</ContentDescription>
        </ContentBlock>
        <ContentBlock>
          <ContentValue>{pickState.roundPick}</ContentValue>
          <ContentDescription>Pick</ContentDescription>
        </ContentBlock>
        <ContentBlock>
          <ContentValue>{
            !!pickUntilNext ? pickUntilNext : <span style={{ color: 'rgb(10 186 72)' }}>Now</span>}</ContentValue>
          <ContentDescription>Next pick</ContentDescription>
        </ContentBlock>
      </ContentBlockContainer>
    </Container>
  )
}