import { createSelector } from '@reduxjs/toolkit'

import { DraftPick, PlayerCount, TeamsWithPicks } from '../../models/draft'
import { AllTiers, PlayerPosition, PlayerV2, PopulatedTier, TeamStatus } from '../../models/player'
import { getCurrRoundPick, getCurrTeam, getNewRound, getPicksUntilNext } from '../../util/pickStateHelper'
import { RootState } from '../store'
import { draftedPlayersSelector, flagsSelector, playerIdToPositionRankSelector, playersByPositionSelector, playersSelector, queuedPlayersSelector, ranksSelector } from './entitySelector'
import { RosterSlot } from '../../models/roster'
import { RosterSlotDisplayOrder, RosterSlotDisplayValue, findSlotIndexToUpdate } from '../../util/rosterHelper'
import { POSITION_SELECTION_OPTIONS, PositionSelectionType } from '../slices/config/draftDrawerRanksConfig'

const draftArenaSelector = (state: RootState) => state.draftArena
export const draftConfigSelector = createSelector(draftArenaSelector, (draftState) => draftState.draftConfig)
export const pickStateSelector = createSelector(draftArenaSelector, (draftState) => draftState.pickState)
export const teamsSelector = createSelector(draftArenaSelector, (draftState) => draftState.teams)
export const picksSelector = createSelector(draftArenaSelector, (draftState) => draftState.picks)
export const queueSelector = createSelector(draftArenaSelector, (draftState) => draftState.queue)
export const userDraftSlotSelector = createSelector(draftArenaSelector, (draftState) => draftState.userDraftSlot)
export const currentPositionForRanksSelector = createSelector(draftArenaSelector, (draftState) => draftState.currentPositionForRanks)
export const isUserPickSelector = createSelector(
  userDraftSlotSelector,
  pickStateSelector,
  (userDraftSlot, pickState) => userDraftSlot === pickState.currTeam
)
export const teamsWithPicksSelector = createSelector(
  picksSelector,
  draftConfigSelector,
  playersSelector,
  (picks, draftConfig, players): TeamsWithPicks => {
    if (!draftConfig || !players || !picks) return {}

    const numDrafters = draftConfig?.numDrafters ?? 1
    const numRounds = getNumRounds(draftConfig?.playerCount as PlayerCount)
    const totalNumPicks = numDrafters * numRounds

    return new Array(totalNumPicks).fill(null).reduce((acc, _, ind) => {
      const overall = ind + 1
      const currTeam = getCurrTeam(overall, numDrafters)
      const currPicks = acc[currTeam] || new Array(numRounds).fill(null)
      const pick: DraftPick = {
        overall,
        roundPick: getCurrRoundPick(overall, numDrafters),
        round: getNewRound(overall, numDrafters),
        player: players[picks[overall]] ? {
          ...players[picks[overall]],
          drafted: true
        } : undefined,
      }
      const round = getNewRound(overall, numDrafters) - 1
      currPicks[round] = pick

      return {
        ...acc,
        [currTeam]: currPicks
      }
    }, {} as TeamsWithPicks)
  }
)

export const picksUntilNextSelector = createSelector(
  userDraftSlotSelector,
  pickStateSelector,
  draftConfigSelector,
  (userDraftSlot, pickState, draftConfig) => {
    return getPicksUntilNext(userDraftSlot, pickState.overall, draftConfig?.numDrafters || 1)
  }
)

const getNumRounds = (playerCount: PlayerCount) =>
  Object.keys(playerCount).reduce((acc, key) =>
    acc + (playerCount[key as keyof PlayerCount] ?? 0)
  , 0)


export const populatedQueueSelector = createSelector(
  playersSelector,
  queueSelector,
  playerIdToPositionRankSelector,
  (players, queuedPlayers, playerIdToPositionRank) => {
    if (!players || !queuedPlayers) {
      return []
    }

    return queuedPlayers.filter(playerId => players[playerId]).map((playerId: number) => ({
      ...players[playerId],
      rank: playerIdToPositionRank[playerId],
      queued: true
    }))
  }
)

export const rosterSelector = createSelector(
  teamsWithPicksSelector,
  draftConfigSelector,
  userDraftSlotSelector,
  (teamsWithPicks, draftConfig, userDraftSlot): RosterSlot[] => {
    if (!draftConfig || !teamsWithPicks) {
      return []
    }

    const userPicks = teamsWithPicks[userDraftSlot]

    if (!userPicks) {
      return []
    }
    const playerCount = draftConfig?.playerCount

    const roster: RosterSlot[] = Object.keys(playerCount)
      .sort((a,b) => RosterSlotDisplayOrder[a  as keyof PlayerCount] - RosterSlotDisplayOrder[b  as keyof PlayerCount])
      .reduce((acc, positionSlotKey) => {
        Array(playerCount[positionSlotKey as keyof PlayerCount]).fill(null).forEach(() => {
          acc.push({
            slot: RosterSlotDisplayValue[positionSlotKey as keyof PlayerCount]
          })
        })
        return acc
      }, [] as RosterSlot[])

    userPicks
      .filter(pick => !!pick.player)
      .sort((a,b) => a.overall - b.overall)
      .forEach(pick => {
        const slotIndexToUpdate = findSlotIndexToUpdate(roster, pick)

        // If no open slot was found, add the player as another bench slot
        if (slotIndexToUpdate === -1) {
          roster.push({
            slot: 'BN',
            pick
          })
        } else {
          roster[slotIndexToUpdate].pick = pick
        }
      })

    return roster
  }
)

const getTeamStatus = (player: PlayerV2, roster: RosterSlot[]): TeamStatus => {
  console.log('hey')
  var teamStatus = TeamStatus.None
  for (const slot of roster) {
    const rosteredPlayer = slot.pick?.player
    if (!rosteredPlayer) {
      continue
    }

    if (rosteredPlayer.team !== player.team) {
      continue
    }

    if ((player.position === "QB" || rosteredPlayer.position === "QB") &&
      player.position !== rosteredPlayer.position) {
      return TeamStatus.Stack
    }

    return TeamStatus.Double
  }
  return teamStatus
}

export const populatedRanksForSelectedPositionSelector = createSelector(
  ranksSelector,
  playersSelector,
  playersByPositionSelector,
  currentPositionForRanksSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  flagsSelector,
  rosterSelector,
  (
    ranks,
    players,
    playersByPosition,
    currentPositionForRanks,
    draftedPlayers,
    queue,
    flags,
    roster,
  ): PopulatedTier[] => {
    if (!Object.keys(players).length || !Object.keys(ranks).length) {
      return []
    }

    switch (POSITION_SELECTION_OPTIONS[currentPositionForRanks].selectionType) {
      case PositionSelectionType.TieredRanks:
        const tieredPositionRank: { [position: string]: number } = {}
        return ranks[currentPositionForRanks].tierOrder.map((tierId, ind) => {
          return {
            playerPosition: currentPositionForRanks,
            tierNumber: ind + 1,
            tierId,
            players: ranks[currentPositionForRanks].tiers[tierId].map(playerId => {
              const player = players[playerId]
              tieredPositionRank[player.position] = tieredPositionRank[player.position] ? tieredPositionRank[player.position] + 1 : 1
    
              return { 
                ...player, 
                drafted: !!draftedPlayers[playerId], 
                userFlag: flags[playerId],
                rank: tieredPositionRank[player.position],
                queued: queue[playerId],
                teamStatus: getTeamStatus(player, roster),
              }
            }).filter(player => !!player.playerId)
          }
        })
      case PositionSelectionType.SinglePosition:
        let singlePositionRank = 1
        return [{
          playerPosition: currentPositionForRanks,
          players: [...playersByPosition[currentPositionForRanks]]
            .sort((a, b) => (players[a].averageDraftPosition?.adp || 1000) - (players[b].averageDraftPosition?.adp || 1000))
            .map(playerId => {
              const player = players[playerId]
              return {
                ...player,
                drafted: !!draftedPlayers[playerId],
                userFlag: flags[playerId],
                rank: singlePositionRank++,
                queued: queue[playerId],
                teamStatus: getTeamStatus(player, roster),
              }
            }),
          tierId: '',
          tierNumber: 0
        }]
      case PositionSelectionType.MultiplePosition:
        const multiPositionRank: { [position: string]: number } = {}
        let playersForAllPositions: string[] = []
        POSITION_SELECTION_OPTIONS[currentPositionForRanks].positions.forEach(position => {
          playersForAllPositions = playersForAllPositions.concat(playersByPosition[position])
        })

        return [{
          playerPosition: currentPositionForRanks,
          players: playersForAllPositions
            .sort((a, b) => (players[a].averageDraftPosition?.adp || 1000) - (players[b].averageDraftPosition?.adp || 1000))
            .map(playerId => {
              const player = players[playerId]
              multiPositionRank[player.position] = multiPositionRank[player.position] ? multiPositionRank[player.position] + 1 : 1
              return {
                ...player,
                drafted: !!draftedPlayers[playerId],
                userFlag: flags[playerId],
                rank: multiPositionRank[player.position],
                queued: queue[playerId],
                teamStatus: getTeamStatus(player, roster),
              }
            }),
          tierId: '',
          tierNumber: 0
        }]
      default:
        return []
    }
  }
)


/**
 * @deprecated
 */
export const populatedRanksSelector = createSelector(
  ranksSelector,
  playersSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  flagsSelector,
  rosterSelector,
  (ranks, players, draftedPlayers, queue, flags, roster): AllTiers => {
    if (!Object.keys(players).length || !Object.keys(ranks).length) {
      return {
        [PlayerPosition.QB]: [],
        [PlayerPosition.RB]: [],
        [PlayerPosition.WR]: [],
        [PlayerPosition.TE]: [],
        [PlayerPosition.OVR]: []
      } as AllTiers
    }

    return Object.keys(ranks).reduce((acc, position) => {
      const positionRank: { [position: string]: number } = {}
      return {
        ...acc,
        [position]: ranks[position].tierOrder.map((tierId, ind) => {
          return {
            playerPosition: position,
            tierNumber: ind + 1,
            tierId,
            players: ranks[position].tiers[tierId].map(playerId => {
              const player = players[playerId]
              positionRank[player.position] = positionRank[player.position] ? positionRank[player.position] + 1 : 1

              return { 
                ...player, 
                drafted: !!draftedPlayers[playerId], 
                userFlag: flags[playerId],
                rank: positionRank[player.position],
                queued: queue[playerId],
              }
            }).filter(player => !!player.playerId)
          }
        })
      }
    }, {}) as AllTiers
  }
)
