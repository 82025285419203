import { FC, FormEvent, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loginPageErrorMessageSelector } from '../../../store/selectors/sessionSelector';
import { loginUserThunk, signUpUserThunk } from '../../../store/thunk/sessionThunk';

export const Login: FC = () => {
  const dispatch = useAppDispatch()
  const errorMessage = useAppSelector(loginPageErrorMessageSelector)
  const [isLogin, setIsLogin] = useState(true)
  const toggleIsLogin = () => setIsLogin(!isLogin)

  const onLoginFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const target = event.target as any
    const username = target.elements.username.value.trim()
    const password = target.elements.password.value.trim()
    dispatch(loginUserThunk({
      username,
      password
    }))
  }

  const onSignUpFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const target = event.target as any
    const email = target.elements.email.value.trim()
    const username = target.elements.username.value.trim()
    const password = target.elements.password.value.trim()
    dispatch(signUpUserThunk({
      username,
      password,
      email
    }))
  }

  const renderLoginForm = () => {
    return (
      <>
        <form action="" onSubmit={onLoginFormSubmit}>
          <div className="form-input">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" id="username" />
          </div>
          <div className="form-input">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" />
          </div>
          <div className="error-message">{errorMessage}</div>
          <button type="submit">Login</button>
        </form>
        <div className="action-toggle" onClick={toggleIsLogin}>
          <p>Need an account? <span className="action-toggle-button">Sign up</span></p>
        </div>
      </>
    )
  }

  const renderSignUpForm = () => {
    return (
      <>
        <form action="" onSubmit={onSignUpFormSubmit}>
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" />
          </div>
          <div className="form-input">
            <label htmlFor="username">Username</label>
            <input type="text" name="username" id="username" />
          </div>
          <div className="form-input">
            <label htmlFor="password">Password</label>
            <input type="password" name="password" id="password" />
          </div>
          <div className="error-message">{errorMessage}</div>
          <button type="submit">Sign Up</button>
        </form>
        <div className="action-toggle" onClick={toggleIsLogin}>
          <p>Already a user? <span className="action-toggle-button">Log In</span></p>
        </div>
      </>
    )
  }

  return (
    <div className="Login">
      <img src={process.env.PUBLIC_URL + 'img/default-monochrome.svg'} alt="" />
      {
        isLogin
          ? renderLoginForm()
          : renderSignUpForm()
      }
    </div>
  )
}