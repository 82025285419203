import { FC } from 'react'

import { PlayerV2 } from '../../../models/player'

type DraftPickCardProps = {
  player?: PlayerV2
  round: number
  roundPick: number
  isCurrentPick: boolean
  selectPlayer: (playerId: number) => void
}

export const DraftPickCard: FC<DraftPickCardProps> = ({
  player,
  round,
  roundPick,
  isCurrentPick,
  selectPlayer,
}) => {
  if (!player) {
    return (
      <div className={`draft-pick-card ${isCurrentPick ? 'on-the-clock' : ''}`}>
        <div className="top">
          <span className="pos-team"></span>
          <span className="round-pick">{round}.{roundPick}</span>
        </div>
        {
          isCurrentPick && (
            <div className="current-pick">
              <span>On the clock!</span>
            </div>
          )
        }
      </div>
    )
  } else {
    return (
      <div className={`draft-pick-card ${player.position} drafted`} onClick={() => selectPlayer(player.playerId)}>
        <div className="top">
          <div className="name">
            <div className="first-name">{player.firstName}</div>
            <div className="last-name">{player.lastName}</div>
          </div>
          <div className="round-pick">{round}.{roundPick}</div>
        </div>
        <div className="bottom">
          <span className="pos-team">{player.position} - {player.team}</span>
          {/* <img src={player.photoUrl} alt='' /> */}
        </div>
      </div>
    )
  }
}