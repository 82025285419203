import { createAsyncThunk } from '@reduxjs/toolkit'

import { getDraftDetails } from '../../clients/proxyClient'
import { GetDraftDetailsThunkFulfilledPayload } from '../models/draftArenaActions'
import { getDraft, getDraftPicks, getUser, getUsersInLeague } from '../../clients/sleeperClient'
import { SLEEPER_TO_FANTASY_DATA_PLAYER_ID } from '../../constant'
import { SleeperPick } from '../../clients/models/sleeper/pick'
import { SleeperDraftSettings } from '../../clients/models/sleeper/draft'
import { SleeperUser } from '../../clients/models/sleeper/user'
import { DraftOrigin } from '../../models/draft'

type GetDraftDetailsThunkRequest = {
  draftId: string
}

export const getDraftDetailsThunk = createAsyncThunk(
  'getDraftDetails',
  async ({ draftId }: GetDraftDetailsThunkRequest): Promise<GetDraftDetailsThunkFulfilledPayload> => {
    const response = await getDraftDetails(draftId)
    const draftDetails = response.data

    return {
      draftDetails: {
        ...draftDetails,
        draftOrigin: DraftOrigin.AdjustTheRanks
      }
    }
  }
)

export const getSleeperDraftDetailsThunk = createAsyncThunk(
  'getSleeperDraftDetails',
  async ({ draftId }: GetDraftDetailsThunkRequest): Promise<GetDraftDetailsThunkFulfilledPayload> => {
    const draftResponse = await getDraft(draftId)
    const picksResponse = await getDraftPicks(draftId)
    const draft = draftResponse.data
    const picks = picksResponse.data

    let userIdToUser: { [userId: string]: SleeperUser } = {}
    if (draft.league_id) {
      const leagueUsersResponse = await getUsersInLeague(draft.league_id)
      userIdToUser = leagueUsersResponse.data.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.user_id]: curr
        }
      }, userIdToUser)
    } else if (draft.creators?.length) {
      const userId = draft.creators[0]
      const userResponse = await getUser(userId)
      userIdToUser[userId] = userResponse.data
    }

    return {
      draftDetails: {
        config: {
          numDrafters: draft.settings.teams,
          playerCount: {
            quarterback: draft.settings.slots_qb,
            runningback: draft.settings.slots_rb,
            wideReceiver: draft.settings.slots_wr,
            tightEnd: draft.settings.slots_te,
            flex: draft.settings.slots_flex,
            defense: draft.settings.slots_def,
            kicker: draft.settings.slots_k,
            superflex: draft.settings.slots_super_flex,
            bench: calculateBenchSpotsForSleeperDraft(draft.settings),
          },
          draftName: draft.metadata.name,
          owner: draft.creators?.length ? draft.creators[0] : undefined,
        },
        draftOrder: Array(draft.settings.teams).fill(null).reduce((acc, _, ind) => {
          const draftSlot = ind + 1
          const userId = Object.keys(draft.draft_order).find(teamId => draft.draft_order[teamId] === draftSlot) ?? ''
          return {
            ...acc,
            [draftSlot]: userIdToUser[userId]?.display_name ?? 'CPU'
          }
        }, {}),
        picks: picks.reduce((acc, pick) => {
          return {
            ...acc,
            [pick.pick_no]: SLEEPER_TO_FANTASY_DATA_PLAYER_ID[pick.player_id] || '0'
          }
        }, {}),
        draftOrigin: DraftOrigin.Sleeper
      }
    }
  }
)

type GetSleeperDraftPicksThunkRequest = {
  draftId: string
}

export const getSleeperDraftPicksThunk = createAsyncThunk(
  'getSleeperDraftPicksThunk',
  async ({ draftId }: GetSleeperDraftPicksThunkRequest): Promise<SleeperPick[]> => {
    const picksResponse = await getDraftPicks(draftId)
    return picksResponse.data
  }
)

const calculateBenchSpotsForSleeperDraft = (draftSettings: SleeperDraftSettings): number =>
  draftSettings.rounds 
    - (draftSettings.slots_qb ?? 0)
    - (draftSettings.slots_rb ?? 0)
    - (draftSettings.slots_wr ?? 0)
    - (draftSettings.slots_te ?? 0)
    - (draftSettings.slots_def ?? 0)
    - (draftSettings.slots_k ?? 0)
    - (draftSettings.slots_flex ?? 0)
    - (draftSettings.slots_super_flex ?? 0)
