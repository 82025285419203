import { createSelector } from '@reduxjs/toolkit';

import { KeyedMap, NumberedMap } from '../../models/common';
import { AllTiers, PlayerPosition, PlayerV2, PopulatedTier, Tier } from '../../models/player';
import { RootState } from '../store';

const entityStateSelector = (state: RootState) => state.entity
export const draftsSelector = createSelector(entityStateSelector, (state) => state.drafts)
export const playersSelector = createSelector(entityStateSelector, state => state.playersByPlayerId)
export const playersByPositionSelector = createSelector(entityStateSelector, state => state.playersByPosition)
export const ranksSelector = createSelector(entityStateSelector, state => state.ranks)
export const flagsSelector = createSelector(entityStateSelector, state => state.flags)
export const draftedPlayersSelector = createSelector(entityStateSelector, state => state.draftedPlayers)
const selectedPlayerIdSelector = createSelector(entityStateSelector, state => state.selectedPlayerId)
export const queuedPlayersSelector = createSelector(entityStateSelector, state => state.queuedPlayers)

const qbTierSelector = createSelector(entityStateSelector, state => state.tiers[PlayerPosition.QB])
const rbTierSelector = createSelector(entityStateSelector, state => state.tiers[PlayerPosition.RB])
const wrTierSelector = createSelector(entityStateSelector, state => state.tiers[PlayerPosition.WR])
const teTierSelector = createSelector(entityStateSelector, state => state.tiers[PlayerPosition.TE])

const populateTiers = (
  playerPosition: PlayerPosition,
  draftedPlayers: KeyedMap<boolean>,
  queuedPlayers: KeyedMap<boolean>,
  players?: KeyedMap<PlayerV2>,
  tiers?: NumberedMap<Tier>,
) => !players || !tiers
    ? []
    : Object.keys(tiers).map(tier => {
      const tierNumber = parseInt(tier)

      return {
        players: tiers[tierNumber].players.map(player => ({
          ...players[player],
          drafted: draftedPlayers[player],
          queued: queuedPlayers[player],
        })),
        playerPosition,
        tierNumber
      } as PopulatedTier
    })

export const playerIdToPositionRankSelector = createSelector(
  ranksSelector,
  (ranks) => {
    const result: { [playerId: string]: number } = {}
    Object.keys(ranks)
      .filter(position => position !== 'OVR')
      .forEach(position => {
        let rank = 1
        ranks[position].tierOrder.forEach(tierId => {
          ranks[position].tiers[tierId].forEach(playerId => {
            result[playerId] = rank++
          })
        })
      })
    return result
  }
)

export const populatedQbTierSelector = createSelector(
  playersSelector,
  qbTierSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  (players, tiers, draftedPlayers, queuedPlayers): PopulatedTier[] => populateTiers(PlayerPosition.QB, draftedPlayers, queuedPlayers, players, tiers)
)
export const populatedRbTierSelector = createSelector(
  playersSelector,
  rbTierSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  (players, tiers, draftedPlayers, queuedPlayers): PopulatedTier[] => populateTiers(PlayerPosition.RB, draftedPlayers, queuedPlayers, players, tiers)
)
export const populatedWrTierSelector = createSelector(
  playersSelector,
  wrTierSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  (players, tiers, draftedPlayers, queuedPlayers): PopulatedTier[] => populateTiers(PlayerPosition.WR, draftedPlayers, queuedPlayers, players, tiers)
)
export const populatedTeTierSelector = createSelector(
  playersSelector,
  teTierSelector,
  draftedPlayersSelector,
  queuedPlayersSelector,
  (players, tiers, draftedPlayers, queuedPlayers): PopulatedTier[] => populateTiers(PlayerPosition.TE, draftedPlayers, queuedPlayers, players, tiers)
)

export const populatedTierSelector = createSelector(
  populatedQbTierSelector,
  populatedRbTierSelector,
  populatedWrTierSelector,
  populatedTeTierSelector,
  (qbTiers, rbTiers, wrTiers, teTiers): AllTiers => ({
    [PlayerPosition.QB]: qbTiers,
    [PlayerPosition.RB]: rbTiers,
    [PlayerPosition.WR]: wrTiers,
    [PlayerPosition.TE]: teTiers,
    [PlayerPosition.OVR]: []
  })
)

export const selectedPlayerSelector = createSelector(
  selectedPlayerIdSelector,
  playersSelector,
  flagsSelector,
  (playerId, players, flags) => !!playerId ? { ...players[playerId], userFlag: flags[playerId] } : undefined
)