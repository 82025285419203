import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

type LoginUserPayload = {
  username: string
  password: string
}

export const loginUserThunk = createAsyncThunk(
  'loginUserThunk',
  async ({ username, password }: LoginUserPayload) => {
    await Auth.signIn(username, password)
  }
)

type SignUpUserPayload = {
  email: string
  username: string
  password: string
}

export const signUpUserThunk = createAsyncThunk(
  'signUpUserThunk',
  async ({ email, username, password }: SignUpUserPayload) => {
    await Auth.signUp({
      password,
      username,
      attributes: { email },
    })
    await Auth.signIn({
      password,
      username
    })
  }
)

export const logoutUserThunk = createAsyncThunk(
  'logoutUserThunk',
  async () => {
    await Auth.signOut()
  }
)

export const getSessionThunk = createAsyncThunk(
  'getSessionThunk',
  async () => {
    await Auth.currentSession()
  }
)