import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { 
  DARK_MODE_BG, 
  DARK_MODE_CTA, 
  DARK_MODE_TEXT_PRIMARY, 
  DARK_MODE_TEXT_SECONDARY 
} from '../../../styles/variables'

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${DARK_MODE_BG};
`

const ButtonContainer = styled.div`
  max-width: 226px;
  padding-top: 210px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const Button = styled.button`
  background: ${DARK_MODE_CTA};
  color: ${DARK_MODE_TEXT_PRIMARY};
  height: 47px;
  width: 100%;
  border-radius: 25px;
  font-size: .9rem;
`

const OrText = styled.div`
  margin: 25px 0;
  color: ${DARK_MODE_TEXT_SECONDARY};
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;

  &::before, &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${DARK_MODE_TEXT_SECONDARY};
  }
  
  &:not(:empty)::before {
    margin-right: 1.25em;
  }
  
  &:not(:empty)::after {
    margin-left: 1.25em;
  }

`

export const CreateDraftIngress: FC = () => {
  return (
    <Container>
      <ButtonContainer>
        <Link to="/drafts/create/sleeper">
          <Button>Create From Sleeper</Button>
        </Link>
        <OrText>or</OrText>
        <Link to="/drafts/create/scratch">
          <Button>Create From Scratch</Button>
        </Link>
      </ButtonContainer>
    </Container>
  )
}