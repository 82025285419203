import { Auth } from 'aws-amplify';
import axios, { AxiosResponse } from 'axios';

import { PlayerPosition } from '../models/player';
import { UpdateRanksRequest } from '../models/ranks';
import {
  AddTierToRanksThunkFulfilledPayload,
  CreateDraftThunkRequest,
  RankPlayerThunkRequest,
  UpdatePlayerDetailThunkRequest,
  UpdatePlayerFlagThunkRequest,
} from '../store/thunk/entityThunk';
import { CreateDraftResponse, DraftPlayerRequest, GetDraftResponse, GetDraftsResponse } from './models/draft';
import { GetPlayerDetailResponse, GetPlayersResponse } from './models/player';
import { GetRanksResponse } from './models/ranks';

const client = axios.create({
  baseURL: 'https://7veai0b9u6.execute-api.us-east-1.amazonaws.com/api'
})

client.interceptors.request.use(async (config: any) => {
  const authHeader = await generateAuthHeader()
  config.headers = {
    ...config.headers,
    ...authHeader
  }
  return config
})

const generateAuthHeader = async () => {
  const currentSession = await Auth.currentSession()
  return { 'Authorization': currentSession.getIdToken().getJwtToken() }
}

export const getDrafts = (start?: string): Promise<AxiosResponse<GetDraftsResponse>> => {
  return client.get<GetDraftsResponse>('/drafts', { params: { start, count: 50 } })
}

export const getDraftDetails = (draftId: string): Promise<AxiosResponse<GetDraftResponse>> => {
  return client.get<GetDraftResponse>(`/drafts/${draftId}`)
}

export const getRanks = (position?: string): Promise<AxiosResponse<GetRanksResponse>> => {
  return client.get<GetRanksResponse>(`/ranks`, { params: { position } })
}

export const saveRanks = (position: PlayerPosition, ranks: UpdateRanksRequest): Promise<AxiosResponse> => {
  return client.put(`/ranks/positions/${position}`, ranks)
}

export const getPlayers = (position?: string): Promise<AxiosResponse<GetPlayersResponse>> => {
  return client.get<GetPlayersResponse>('/players', { params: { position, filter: 'ALL' } })
}

export const getPlayerDetail = (playerId: number): Promise<AxiosResponse<GetPlayerDetailResponse>> => {
  return client.get<GetPlayerDetailResponse>(`/players/${playerId}`)
}

export const createDraft = (draftConfig: CreateDraftThunkRequest): Promise<AxiosResponse<CreateDraftResponse>> => {
  return client.post<CreateDraftResponse>('/drafts', draftConfig)
}

export const draftPlayer = (
  playerId: number,
  draftId: string,
  pickNumber: number
): Promise<AxiosResponse> => {
  const request: DraftPlayerRequest = {
    playerId,
    pickNumber
  }
  return client.post(`/drafts/${draftId}/picks`, request)
}

export const deleteDraftPick = (draftId: string, pickNumber: number): Promise<AxiosResponse> => {
  return client.delete(`/drafts/${draftId}/picks/${pickNumber}`)
}

export const rankPlayer = (request: RankPlayerThunkRequest): Promise<AxiosResponse> => {
  return client.put(`/ranks/${request.position}/players/${request.playerId}`, {
    oldTierId: request.oldTierId !== 'unranked' ? request.oldTierId : undefined,
    newTierId: request.newTierId,
    oldIndexWithinTier: request.oldIndexWithinTier,
    newIndexWithinTier: request.newIndexWithinTier
  })
}

export const updatePlayerFlag = (request: UpdatePlayerFlagThunkRequest): Promise<AxiosResponse> => {
  return client.put(`/ranks/${request.position}/players/${request.playerId}/flags`, {
    flag: request.flag
  })
}

export const updatePlayerDetail = (request: UpdatePlayerDetailThunkRequest): Promise<AxiosResponse> => {
  return client.put(`/players/${request.playerId}`, {
    notes: request.notes
  })
}

export const addTierToRanks = (position: string, tierIndex: number): Promise<AxiosResponse<AddTierToRanksThunkFulfilledPayload>> => {
  return client.put<AddTierToRanksThunkFulfilledPayload>(`/ranks/${position}`, {
    tierIndex
  })
}

export const deleteTier = (position: string, tierId: string): Promise<AxiosResponse> => {
  return client.delete(`/ranks/${position}/tiers/${tierId}`)
}