import { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  isUserPickSelector,
  pickStateSelector,
  populatedQueueSelector,
  teamsWithPicksSelector,
  teamsSelector,
  rosterSelector,
  populatedRanksForSelectedPositionSelector,
  currentPositionForRanksSelector,
  userDraftSlotSelector,
} from '../../../store/selectors/draftArenaSelector';
import { selectedPlayerSelector } from '../../../store/selectors/entitySelector';
import {
  deleteDraftPickThunk,
  dequeuePlayer as dequeuePlayerAction,
  draftPlayerThunk,
  queuePlayer as queuePlayerAction,
  updateQueueOrder as updateQueueOrderAction,
  updateCurrentPositionForRanks as updateCurrentPositionForRanksAction,
  claimDraftSlot as claimDraftSlotAction,
} from '../../../store/slices/draftArenaSlice';
import { updateSelectedPlayerId } from '../../../store/slices/entitySlice';
import { getDraftDetailsThunk, getSleeperDraftDetailsThunk, getSleeperDraftPicksThunk } from '../../../store/thunk/draftArenaThunk';
import { getPlayersThunk, getRanksThunk } from '../../../store/thunk/entityThunk';
import { DraftBoard } from '../../components/draft-board/DraftBoard';
import { DraftToolbar } from '../../components/draft-toolbar/DraftToolbar';
import { PlayerModal } from '../../components/modal/PlayerModal';
import { DraftDrawer } from '../../components/draft-drawer/DraftDrawer';

const RANKS_VIEWS = {
  DEFAULT: '',
  FULL_RANKS: 'full-screen-ranks',
  FULL_BOARD: 'full-screen-board'
}
const RANKS_VIEWS_ORDER = [RANKS_VIEWS.FULL_BOARD, RANKS_VIEWS.DEFAULT, RANKS_VIEWS.FULL_RANKS]

type MatchParams = {
  draftId: string
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const DraftArena: FC<RouteComponentProps<MatchParams>> = ({
  match
}) => {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const pickState = useAppSelector(pickStateSelector)
  const teamsWithPicks = useAppSelector(teamsWithPicksSelector)
  const teams = useAppSelector(teamsSelector)
  const playerQueue = useAppSelector(populatedQueueSelector)
  const selectedPlayer = useAppSelector(selectedPlayerSelector)
  const isUserPick = useAppSelector(isUserPickSelector)
  const roster = useAppSelector(rosterSelector)
  const populatedRanksForSelectedPosition = useAppSelector(populatedRanksForSelectedPositionSelector)
  const currentPositionForRanks = useAppSelector(currentPositionForRanksSelector)
  const userDraftSlot = useAppSelector(userDraftSlotSelector)

  const [ranksView, setRanksView] = useState(1)

  const draftPlayer = (playerId: number) => dispatch(draftPlayerThunk({ playerId }))
  const queuePlayer = (playerId: number) => dispatch(queuePlayerAction({ playerId }))
  const dequeuePlayer = (playerId: number) => dispatch(dequeuePlayerAction({ playerId }))
  const undoPick = () => dispatch(deleteDraftPickThunk())
  const updateQueueOrder = (oldIndex: number, newIndex: number) => dispatch(updateQueueOrderAction({ oldIndex, newIndex }))
  const selectPlayer = (playerId: number) => dispatch(updateSelectedPlayerId(playerId))
  const onModalClose = () => dispatch(updateSelectedPlayerId(undefined))
  const updatedCurrentPositionForRanks = (position: string) => dispatch(updateCurrentPositionForRanksAction({  position }))
  const claimDraftSlot = (draftSlot: number) => dispatch(claimDraftSlotAction({ draftSlot }))

  const updateRanksView = (incrementer: number) => {
    const newRanksView = ranksView + incrementer
    if (newRanksView < 0 || newRanksView >= RANKS_VIEWS_ORDER.length) {
      return 
    }
    setRanksView(newRanksView)
  }

  const isSleeperDraft = query.get('origin') === 'sleeper'

  useEffect(() => {
    const draftId = match.params.draftId
    let refreshInterval: NodeJS.Timeout

    if (isSleeperDraft) {
      dispatch(getSleeperDraftDetailsThunk({ draftId }))

      refreshInterval = setInterval(() => {
        dispatch(getSleeperDraftPicksThunk({ draftId }))
      }, 1000)
    } else {
      dispatch(getDraftDetailsThunk({ draftId }))
    }
    dispatch(getPlayersThunk({}))
    dispatch(getRanksThunk({}))

    return () => {
      clearInterval(refreshInterval)
    }
  }, [dispatch, match.params.draftId, isSleeperDraft])

  return (
    <div className={`DraftArena ${RANKS_VIEWS_ORDER[ranksView]}`}> 
      <DraftToolbar pickState={pickState} />
      <PlayerModal player={selectedPlayer} onClose={onModalClose} />
      <div className="board-container">
        <DraftBoard 
          teamsWithPicks={teamsWithPicks} 
          teams={teams} 
          pickState={pickState}
          userDraftSlot={userDraftSlot}
          showClaimButton={isSleeperDraft}
          onClaimDraftSlotClick={claimDraftSlot}
          selectPlayer={selectPlayer}
          />
      </div>
      <DraftDrawer 
        tiers={populatedRanksForSelectedPosition}
        roster={roster}
        queue={playerQueue}
        isUserPick={isUserPick}
        currentPositionForRanks={currentPositionForRanks}
        hideDraftPickActionButtons={isSleeperDraft}
        draftPlayer={draftPlayer}
        queuePlayer={queuePlayer}
        dequeuePlayer={dequeuePlayer}
        selectPlayer={selectPlayer}
        updateQueueOrder={updateQueueOrder}
        updateCurrentPositionForRanks={updatedCurrentPositionForRanks}
        undoPick={undoPick}
        updateRanksView={updateRanksView}
        />
    </div>
  )
}