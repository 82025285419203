export enum PositionSelectionType {
  TieredRanks,
  SinglePosition,
  MultiplePosition
}

export type PositionSelectionOption = {
  displayValue: string
  sortOrder: number
  positions: string[]
  isSinglePosition: boolean
  displayTierLabels: boolean
  selectionType: PositionSelectionType
}

export const POSITION_SELECTION_OPTIONS: { [position: string]: PositionSelectionOption} = {
  'OVR': {
    displayValue: 'OVR',
    sortOrder: 1,
    positions: ['OVR'],
    isSinglePosition: true,
    displayTierLabels: true,
    selectionType: PositionSelectionType.TieredRanks
  },
  'QB': {
    displayValue: 'QB',
    sortOrder: 2,
    positions: ['QB'],
    isSinglePosition: true,
    displayTierLabels: true,
    selectionType: PositionSelectionType.TieredRanks
  },
  'RB': {
    displayValue: 'RB',
    sortOrder: 3,
    positions: ['RB'],
    isSinglePosition: true,
    displayTierLabels: true,
    selectionType: PositionSelectionType.TieredRanks
  },
  'WR': {
    displayValue: 'WR',
    sortOrder: 4,
    positions: ['WR'],
    isSinglePosition: true,
    displayTierLabels: true,
    selectionType: PositionSelectionType.TieredRanks
  },
  'TE': {
    displayValue: 'TE',
    sortOrder: 5,
    positions: ['TE'],
    isSinglePosition: true,
    displayTierLabels: true,
    selectionType: PositionSelectionType.TieredRanks
  },
  // 'FLX': {
  //   displayValue: 'FLX',
  //   sortOrder: 6,
  //   positions: ['RB', 'WR', 'TE'],
  //   isSinglePosition: false,
  //   displayTierLabels: false,
  //   selectionType: PositionSelectionType.MultiplePosition
  // },
  'K': {
    displayValue: 'K',
    sortOrder: 7,
    positions: ['K'],
    isSinglePosition: true,
    displayTierLabels: false,
    selectionType: PositionSelectionType.SinglePosition
  },
  'DST': {
    displayValue: 'DST',
    sortOrder: 8,
    positions: ['DST'],
    isSinglePosition: true,
    displayTierLabels: false,
    selectionType: PositionSelectionType.SinglePosition
  }
}
