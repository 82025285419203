import { FC } from 'react'
import { RanksTableHeaders } from './RanksTableHeaders'
// import { TierTable } from './TierTable'
import { PopulatedTier } from '../../../../../models/player'
// import styled from 'styled-components'

type RanksTableProps = {
  tiers: PopulatedTier[]
  isUserPick: boolean
  displayTierLabels: boolean
  searchQuery: string
  hideDraftButton?: boolean
  draftPlayer: (playerId: number) => void
  queuePlayer: (playerId: number) => void
  dequeuePlayer: (playerId: number) => void
  selectPlayer: (playerId: number) => void
}

// const ScrollableContainer = styled.div`
//   overflow: auto;
//   height: calc(100% - 90px);

//   > table:last-of-type {
//     margin-bottom: 15px;
//   }
// `

export const RanksTable: FC<RanksTableProps> = ({ 
  tiers,
  isUserPick,
  displayTierLabels,
  searchQuery,
  hideDraftButton,
  draftPlayer,
  queuePlayer,
  dequeuePlayer,
  selectPlayer,
}: RanksTableProps) => {
  return (
    <>
      <RanksTableHeaders hideDraftButton={hideDraftButton} 
              tiers={tiers} 
              isUserPick={isUserPick}

              searchQuery={searchQuery}
              displayTierLabels={displayTierLabels}
              draftPlayer={draftPlayer}
              queuePlayer={queuePlayer}
              dequeuePlayer={dequeuePlayer}
              selectPlayer={selectPlayer}/>
      {/* <ScrollableContainer>
        {
          tiers.filter(tier => tier.players.some(player => !player.drafted && player.name.toLowerCase().indexOf(searchQuery) !== -1)).map(tier => (
            <TierTable 
              key={tier.tierId}
              tier={tier} 
              isUserPick={isUserPick}
              hideDraftButton={hideDraftButton}
              searchQuery={searchQuery}
              displayTierLabels={displayTierLabels}
              draftPlayer={draftPlayer}
              queuePlayer={queuePlayer}
              dequeuePlayer={dequeuePlayer}
              selectPlayer={selectPlayer}
              />
          ))
        }
      </ScrollableContainer> */}

    </>
  )
}