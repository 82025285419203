import axios, { AxiosResponse } from 'axios'
import { SleeperDraft } from './models/sleeper/draft'
import { SleeperPick } from './models/sleeper/pick'
import { SleeperUser } from './models/sleeper/user'
import { SleeperLeagueUser } from './models/sleeper/league'

const client = axios.create({
  baseURL: 'https://api.sleeper.app/v1'
})

export const getUser = async (userId: string): Promise<AxiosResponse<SleeperUser>> => {
  return await client.get<SleeperUser>(`/user/${userId}`)
}

export const getUsersInLeague = async (leagueId: string): Promise<AxiosResponse<SleeperLeagueUser[]>> => {
  return await client.get<SleeperLeagueUser[]>(`/league/${leagueId}/users`)
}

export const getDraftsForUser = async (
  userId: string,
  draftYear: number
): Promise<AxiosResponse<SleeperDraft[]>> => {
  return await client.get<SleeperDraft[]>(`/user/${userId}/drafts/nfl/${draftYear}`)
}

export const getDraft = async (
  draftId: string
): Promise<AxiosResponse<SleeperDraft>> => {
  return await client.get<SleeperDraft>(`/draft/${draftId}`)
}

export const getDraftPicks = async (
  draftId: string
): Promise<AxiosResponse<SleeperPick[]>> => {
  return await client.get<SleeperPick[]>(`/draft/${draftId}/picks`)
}