import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useState } from 'react'
import { GrUndo } from 'react-icons/gr'
import { PositionSelector } from '../position-selector/PositionSelector'
import { RanksTable } from '../ranks/v2/ranks-table/RanksTable'
import { PlayerV2, PopulatedTier } from '../../../models/player'
import { TeamRoster } from './roster/TeamRoster'
import { RosterSlot } from '../../../models/roster'
import { Queue } from './queue/Queue'
import { POSITION_SELECTION_OPTIONS } from '../../../store/slices/config/draftDrawerRanksConfig'
import { DARK_MODE_TEXT_PRIMARY } from '../../../styles/variables'
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi'

type DraftDrawerProps = {
  tiers: PopulatedTier[]
  roster: RosterSlot[]
  queue: PlayerV2[]
  isUserPick: boolean
  currentPositionForRanks: string
  hideDraftPickActionButtons?: boolean
  draftPlayer: (playerId: number) => void
  queuePlayer: (playerId: number) => void
  dequeuePlayer: (playerId: number) => void
  updateQueueOrder: (oldIndex: number, newIndex: number) => void
  updateCurrentPositionForRanks: (position: string) => void
  undoPick: () => void
  selectPlayer: (playerId: number) => void
  updateRanksView: (incrementer: number) => void
}

enum DraftDrawerTabs {
  Queue,
  Roster,
  Ranks
}

const isMobile = () => window.screen.width < 800;

export const DraftDrawer: FC<DraftDrawerProps> = ({
  tiers,
  roster,
  queue,
  isUserPick,
  currentPositionForRanks,
  hideDraftPickActionButtons,
  draftPlayer,
  queuePlayer,
  dequeuePlayer,
  updateQueueOrder,
  updateCurrentPositionForRanks,
  undoPick,
  selectPlayer,
  updateRanksView,
}: DraftDrawerProps) => {
  const [selectedTab, setSelectedTab] = useState(isMobile() ? DraftDrawerTabs.Ranks : DraftDrawerTabs.Queue)
  const [searchQuery, setSearchQuery] = useState('')

  const renderToolsContent = () => {
    switch (selectedTab) {
      case DraftDrawerTabs.Queue:
        return (
          <div className="content-container">
            <Queue 
            queue={queue} 
            dequeuePlayer={dequeuePlayer}
            updateQueueOrder={updateQueueOrder}
            draftPlayer={draftPlayer}
            hideDraftButton={hideDraftPickActionButtons}
            isUserPick={isUserPick}
            selectPlayer={selectPlayer}
            />
          </div>
        )
      case DraftDrawerTabs.Roster:
        return (
          <div className="content-container">
            <TeamRoster 
              roster={roster} 
              selectPlayer={selectPlayer}
              />
          </div>
        )
      case DraftDrawerTabs.Ranks:
        return renderRanks()
      default:
        return null
    }
  }

  const handleKeyboardEnterPress = (e: KeyboardEvent) => {
    if (e.key !== 'Enter' || hideDraftPickActionButtons) {
      return
    }
    const candidates = tiers.map(tier => {
      return tier.players.filter(player => !player.drafted && player.name.toLowerCase().indexOf(searchQuery) !== -1)
    }).flat()
    if (!candidates.length) {
      return
    }
    draftPlayer(candidates[0].playerId)
    setSearchQuery('')
  }

  const renderRanks = () => {
    return (
      <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '15px 15px 0' }}>
              <PositionSelector 
                selectedPosition={currentPositionForRanks} 
                setSelectedPosition={updateCurrentPositionForRanks}
                options={Object.values(POSITION_SELECTION_OPTIONS).sort((a,b) => a.sortOrder - b.sortOrder)} 
                />
              <div style={{ display: 'flex', columnGap: '15px' }}>
                {
                  !isMobile() ? (
                    <div className="searchbar">
                      <input 
                        type="text" 
                        placeholder="Search for player..."
                        value={searchQuery} 
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)} 
                        onBlur={(e: FocusEvent<HTMLInputElement>) => { e.target.focus() }}
                        onKeyUp={handleKeyboardEnterPress}
                        />
                    </div>
                  ) : null
                }
                {
                  !hideDraftPickActionButtons && (
                    <div 
                      onClick={undoPick}
                      style={{ 
                        color: DARK_MODE_TEXT_PRIMARY, 
                        border: `1px solid ${DARK_MODE_TEXT_PRIMARY}`, 
                        borderRadius: '55px', 
                        height: '35px', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        padding: '0 10px', 
                        cursor: 'pointer' ,
                        position: 'relative',
                        top: '-4px'
                      }}>
                      <GrUndo style={{ height: '35px', width: '17px',}} />
                    </div>
                  )
                }
              </div>
            </div>
            <RanksTable 
              tiers={tiers} 
              isUserPick={isUserPick}
              searchQuery={searchQuery}
              displayTierLabels={POSITION_SELECTION_OPTIONS[currentPositionForRanks].displayTierLabels}
              hideDraftButton={hideDraftPickActionButtons}
              draftPlayer={draftPlayer}
              queuePlayer={queuePlayer}
              dequeuePlayer={dequeuePlayer}
              selectPlayer={selectPlayer}
              />
      </>
    )
  }

  return (
    <div className="DraftDrawer">
      {
        !isMobile() ? (
          <div className="drawer-size-controls">
            <div className="drawer-size-control" onClick={() => updateRanksView(1)}>
              <HiOutlineChevronUp size="1.2rem" />
            </div>
            <div className="drawer-size-control" onClick={() => updateRanksView(-1)}>
              <HiOutlineChevronDown size="1.2rem" />
            </div>
          </div>
        ) : null
      }
      {
        isMobile() ? (
          <div className="ranks">
            <div className="tool-selector-mobile">
              <div 
                onClick={() => setSelectedTab(DraftDrawerTabs.Ranks)}
                className={`tab ${selectedTab === DraftDrawerTabs.Ranks ? 'selected' : ''}`}>
                  RANKS
              </div>
              <div 
                onClick={() => setSelectedTab(DraftDrawerTabs.Queue)}
                className={`tab ${selectedTab === DraftDrawerTabs.Queue ? 'selected' : ''}`}>
                  QUEUE { queue.length ? `(${queue.length})` : ''}
              </div>
              <div 
                onClick={() => setSelectedTab(DraftDrawerTabs.Roster)}
                className={`tab ${selectedTab === DraftDrawerTabs.Roster ? 'selected' : ''}`}>
                  ROSTER
              </div>

            </div>

            { renderToolsContent() }
          </div>
        ) : (
          <>
          <div className="ranks">
            { renderRanks() }
          </div>
          <div className="tools">
            <div className="tools-tabs">
              <div 
                onClick={() => setSelectedTab(DraftDrawerTabs.Queue)}
                className={`tab ${selectedTab === DraftDrawerTabs.Queue ? 'selected' : ''}`}>
                  QUEUE { queue.length ? `(${queue.length})` : ''}
              </div>
              <div 
                onClick={() => setSelectedTab(DraftDrawerTabs.Roster)}
                className={`tab ${selectedTab === DraftDrawerTabs.Roster ? 'selected' : ''}`}>
                  ROSTER
              </div>
            </div>
            <div className="tools-content">
              { renderToolsContent() }
            </div>
          </div>
          </>
        )
      }
    </div>
  )
}