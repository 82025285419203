import { FC } from 'react'
import styled from 'styled-components'
import { RosterSlotRow } from './RosterSlotRow'
import { RosterSlot } from '../../../../models/roster'

type TeamRosterProps = {
  roster: RosterSlot[]
  selectPlayer: (playerId: number) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`

export const TeamRoster: FC<TeamRosterProps> = ({
  roster,
  selectPlayer,
}: TeamRosterProps) => {
  return (
    <Container>
      {
        roster.map(rosterSlot => (
          <RosterSlotRow slot={rosterSlot} selectPlayer={selectPlayer}/>
        ))
      }
    </Container>
  )
}