import { FC } from 'react'
import styled from 'styled-components'
import { BN_COLOR, DARK_MODE_DRAWER_ROW, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY, DST_COLOR, FLEX_COLOR, K_COLOR, QB_COLOR, RB_COLOR, TE_COLOR, WR_COLOR } from '../../../../styles/variables'
import { RosterSlot } from '../../../../models/roster'

type RosterSlotRowProps = {
  slot: RosterSlot
  selectPlayer: (playerId: number) => void
}

const POSITION_TO_COLOR: { [rosterSlot: string]: string } = {
  'QB': QB_COLOR,
  'RB': RB_COLOR,
  'WR': WR_COLOR,
  'TE': TE_COLOR,
  'FLX': FLEX_COLOR,
  'BN': BN_COLOR,
  'K': K_COLOR,
  'SF': FLEX_COLOR,
  'DST': DST_COLOR
}

const Row = styled.div<{ $isSlotFilled: boolean }>`
  height: 44px;
  background: ${DARK_MODE_DRAWER_ROW};
  padding: 5px 20px 5px 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  color: ${DARK_MODE_TEXT_PRIMARY};
  cursor: ${props => props.$isSlotFilled ? 'pointer' : '' };
`

const PositionPlayer = styled.div`
  display: flex;
`

const PositionIndicator = styled.div<{ $rosterSlot: string }>`
  background:  ${props => POSITION_TO_COLOR[props.$rosterSlot]};
  border-radius: 50px;
  height: 100%;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 18px;
`

const Player = styled.div`
  display: flex;
  align-items: center;
`

const PlayerInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const EmptyPlayer = styled.div`
  align-self: center;
  letter-spacing: .05rem;
`

const PlayerImage = styled.img`
  height: 37px;
  margin-right: 6px;
`

const PlayerName = styled.div`
  font-size: 1rem;
  letter-spacing: .01rem;
`

const PlayerTeam = styled.div`
  font-size: .6rem;
  color: ${DARK_MODE_TEXT_SECONDARY};
  font-weight: 700;
`

const Pick = styled.div`
  font-size: 1.2rem;
  align-self: center;
  font-weight: 300;
`

export const RosterSlotRow: FC<RosterSlotRowProps> = ({
  slot,
  selectPlayer,
}: RosterSlotRowProps) => {
  const handleRowClick = () => {
    if (slot.pick?.player) {
      selectPlayer(slot.pick.player.playerId)
    }
  }

  return (
    <Row onClick={handleRowClick} $isSlotFilled={!!slot.pick}>
      <PositionPlayer>
        <PositionIndicator $rosterSlot={slot.slot}>{slot.slot}</PositionIndicator>
        {
          slot.pick ? (
            <Player>
              <PlayerImage src={slot.pick?.player?.photoUrl} />
              <PlayerInfo>
                <PlayerName>{slot.pick?.player?.name}</PlayerName>
                <PlayerTeam>{slot.pick?.player?.position} - {slot.pick?.player?.team}</PlayerTeam>
              </PlayerInfo>
            </Player>
          ) : (
            <EmptyPlayer>Empty</EmptyPlayer>
          )
        }
      </PositionPlayer>
      {
        slot.pick ? (
          <Pick>{slot.pick?.round}.{slot.pick?.roundPick}</Pick>
        ) : null
      }
    </Row>
  )
}