import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { GetPlayerDetailResponse, GetPlayersResponse } from '../../clients/models/player';
import {
  addTierToRanks,
  createDraft,
  deleteTier,
  getDrafts,
  getPlayerDetail,
  getPlayers,
  getRanks,
  rankPlayer,
  updatePlayerDetail,
  updatePlayerFlag,
} from '../../clients/proxyClient';
import { DraftConfig, DraftsPage } from '../../models/draft';
import { UserFlag, UserFlags } from '../../models/player';
import { RanksByPosition } from '../../models/ranks';

type GetDraftsThunkRequest = {
  start?: string
}
export type GetDraftsThunkFulfilledPayload = DraftsPage

export const getDraftsThunk = createAsyncThunk(
  'getDraftsThunk',
  async ({ start }: GetDraftsThunkRequest): Promise<GetDraftsThunkFulfilledPayload> => {
    const response = await getDrafts(start)
    return response.data
  }
)

type GetRanksThunkRequest = {
  position?: string
}

export type GetRanksThunkFulfilledPayload = {
  ranks: RanksByPosition
  flags?: UserFlags
}

export const getRanksThunk = createAsyncThunk(
  'getRanksThunk',
  async ({ position }: GetRanksThunkRequest): Promise<GetRanksThunkFulfilledPayload> => {
    const ranks = await getRanks(position)
    return ranks.data
  }
)

export type CreateDraftThunkRequest = {
  draftConfig: DraftConfig
  ownerDraftPosition: number
}

export const createDraftThunk = createAsyncThunk(
  'createDraftThunk',
  async (draftConfig: CreateDraftThunkRequest, { dispatch }): Promise<void> => {
    const response = await createDraft(draftConfig)
    const draftId = response.data.draftId
    dispatch(push(`/drafts/${draftId}`))
  }
)

export type GetPlayersThunkRequest = {
  position?: string
}

export type GetPlayersThunkFulfilledPayload = GetPlayersResponse

export const getPlayersThunk = createAsyncThunk(
  'getPlayersThunk',
  async ({ position }: GetPlayersThunkRequest): Promise<GetPlayersThunkFulfilledPayload> => {
    const response = await getPlayers(position)
    return response.data
  },
  {
    condition: (_, { getState }) => {
      return !Object.keys((getState() as any).entity.playersByPlayerId).length
    }
  }
)

export type GetPlayerDetailThunkFulfilledPayload = GetPlayerDetailResponse

export const getPlayerDetailThunk = createAsyncThunk(
  'getPlayerDetailThunk',
  async (playerId: number) => {
    const response = await getPlayerDetail(playerId)
    return response.data
  }
)

export type RankPlayerThunkRequest = {
  position: string
  playerId: number
  oldTierId: string | 'unranked'
  newTierId: string | 'unranked'
  oldIndexWithinTier?: number // Set in pending reducer
  newIndexWithinTier: number
}

export const rankPlayerThunk = createAsyncThunk(
  'rankPlayerThunk',
  async (request: RankPlayerThunkRequest) => {
    await rankPlayer(request)
  }
)

export type UpdatePlayerFlagThunkRequest = {
  position: string
  playerId: number
  flag: UserFlag
}

export const updatePlayerFlagThunk = createAsyncThunk(
  'updatePlayerFlagThunk',
  async (request: UpdatePlayerFlagThunkRequest) => {
    await updatePlayerFlag(request)
  }
)

export type UpdatePlayerDetailThunkRequest = {
  playerId: number
  notes: string
}

export const updatePlayerDetailThunk = createAsyncThunk(
  'updatePlayerDetailThunk',
  async (request: UpdatePlayerDetailThunkRequest) => {
    await updatePlayerDetail(request)
  }
)

type AddTierToRanksRequest = {
  tierIndex: number
  position: string
}

export type AddTierToRanksThunkFulfilledPayload = {
  tierId: string
}

export const addTierToRanksThunk = createAsyncThunk(
  'addTierToRanks',
  async ({ position, tierIndex }: AddTierToRanksRequest) => {
    const response = await addTierToRanks(position, tierIndex)
    return response.data
  }
)

export type DeleteTierThunkRequest = {
  position: string
  tierId: string
}

export const deleteTierThunk = createAsyncThunk(
  'deleteTier',
  async ({ position, tierId }: DeleteTierThunkRequest) => {
    await deleteTier(position, tierId)
  }
)