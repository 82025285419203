import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import draftArenaSlice from '../store/slices/draftArenaSlice';
import entitySlice from './slices/entitySlice';
import sessionSlice from './slices/sessionSlice';
import userRanksSlice from './slices/userRanksSlice';

export const history = createBrowserHistory()

export const store = configureStore({
  reducer: {
    draftArena: draftArenaSlice,
    entity: entitySlice,
    session: sessionSlice,
    userRanks: userRanksSlice,
    router: connectRouter(history),
  },
  middleware: getDefaultMiddleware().concat(routerMiddleware(history)),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
