import { FC, MouseEvent } from 'react'
import styled from 'styled-components'
import { TbPlaylistAdd, TbPlaylistX } from 'react-icons/tb'
import { BiTargetLock } from 'react-icons/bi'
import { TbEyeCheck } from 'react-icons/tb'
import { GoStack } from 'react-icons/go'
import { SlPeople } from 'react-icons/sl'
import { LuFlag } from 'react-icons/lu'

import { DARK_MODE_CTA, DARK_MODE_DRAWER_ROW, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY, POSITION_INDICATOR_COLOR } from '../../../../../styles/variables'
import { PlayerV2, PopulatedTier, TeamStatus, UserFlag } from '../../../../../models/player'

const DraftButton = styled.button<{ $isUserPick: boolean }>`
  border: 1px solid #4B59FB;
  background: ${ props => props.$isUserPick ? '#4B59FB' : 'transparent' };
  color: ${DARK_MODE_TEXT_PRIMARY};
  width: 60px;
  height: 30px;
  font-size: .9rem;

  @media only screen and (max-width: 760px) {
    font-size: .8rem;
  }
`

type RanksTableHeadersProps = {
  hideDraftButton?: boolean
  tiers: PopulatedTier[]
  isUserPick: boolean
  displayTierLabels: boolean
  searchQuery: string
  draftPlayer: (playerId: number) => void
  queuePlayer: (playerId: number) => void
  dequeuePlayer: (playerId: number) => void
  selectPlayer: (playerId: number) => void
}

const Table = styled.table`
  width: calc(100% - 30px);
  border-collapse: collapse;
  margin-bottom: 4px;
  margin: 0 15px 15px;
`

const TableHeaderRow = styled.tr`
  padding: 0 18px;
`

const TableHeaderCell = styled.th<{ $isLeftAlign?: boolean }>`
  color: ${DARK_MODE_TEXT_PRIMARY};
  font-size: .6rem;
  vertical-align: bottom;
  text-align: ${ props => props.$isLeftAlign ? 'left' : 'right' };

  &:first-of-type {
    padding-left: 14px;
  }

  &:last-of-type {
    padding-right: 18px;
  }
`

const TierLabelRow = styled.tr`
  color: ${DARK_MODE_TEXT_PRIMARY};
  border-bottom: 2px solid #28293b;
`

const TierLabelCell = styled.td`
  width: 100%;
  font-size: .7rem;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  padding: 0 16px;
  height: 16px;
  background: #545677;
`

const TableRow = styled.tr`
  background: ${DARK_MODE_DRAWER_ROW};
  height: 40px;
  border-bottom: 2px solid #28293b;
  cursor: pointer;
`

const TableRowCell = styled.td<{ $isLeftAlign?: boolean }>`
  color: ${DARK_MODE_TEXT_PRIMARY};
  vertical-align: middle;
  text-align: ${ props => props.$isLeftAlign ? 'left' : 'right' };
  font-size: .9rem;


  &:first-of-type {
    padding-left: 14px;
  }

  &:last-of-type {
    padding-right: 18px;
  }
`

const ScrollableContainer = styled.div`
  overflow: auto;
  height: calc(100% - 64px);
  // width: 100vw;
`

export const RanksTableHeaders: FC<RanksTableHeadersProps> = ({
  hideDraftButton,
  isUserPick,
  displayTierLabels,
  searchQuery,
  draftPlayer,
  queuePlayer,
  dequeuePlayer,
  selectPlayer,
  tiers
}: RanksTableHeadersProps) => {

  const renderUserFlagIcon = (userFlag?: UserFlag) => {
    switch (userFlag) {
      case UserFlag.AllIn:
        return <BiTargetLock color="#02d402" size="1.2rem" />
      case UserFlag.AllOut:
        return <LuFlag color="#eb0000" size="1.2rem" />
      case UserFlag.Neutral:
        return <TbEyeCheck color="#fffc00" size="1.2rem" />
      case UserFlag.Unranked:
      default:
        return null
    }
  }

  const renderTeamStatusIcon = (teamStatus?: TeamStatus) => {
    switch (teamStatus) {
      case TeamStatus.Stack:
        return <GoStack color="#02d402" size="1.2rem" />
      case TeamStatus.Double:
        return <SlPeople color="#eb0000" size="1.2rem"/>
      default:
        return ""
    }
  }

  const handleQueueButtonClick = (e: MouseEvent<HTMLTableCellElement>, player: PlayerV2) => {
    e.stopPropagation()

    if (player.queued) {
      dequeuePlayer(player.playerId)
    } else {
      queuePlayer(player.playerId)
    }
  }

  const handleDraftButtonClick = (e: MouseEvent<HTMLTableCellElement>, playerId: number) => {
    e.stopPropagation()
    draftPlayer(playerId)
  }
  return (
    <ScrollableContainer>
    <Table>
      <thead>
        <TableHeaderRow>
          <TableHeaderCell style={{ width: '5%' }}></TableHeaderCell>
          {
            !hideDraftButton ? (
              <TableHeaderCell style={{ width: '7%' }}></TableHeaderCell>
            ) : null
          }
          <TableHeaderCell style={{ width: '4%' }} $isLeftAlign>Rank</TableHeaderCell>
          <TableHeaderCell style={{ width: '2%' }}>Tier</TableHeaderCell>
          <TableHeaderCell style={{ width: '4%' }}/>
          <TableHeaderCell style={{ width: '4%' }}/>
          <TableHeaderCell style={{ width: '3%' }}></TableHeaderCell>
          <TableHeaderCell $isLeftAlign>Player</TableHeaderCell>
          <TableHeaderCell style={{ width: '7%' }}>ADP</TableHeaderCell>
          <TableHeaderCell style={{ width: '7%' }}>ADP<br/>Pos. Rank</TableHeaderCell>
          <TableHeaderCell style={{ width: '5%' }}>Bye</TableHeaderCell>
          <TableHeaderCell style={{ width: '5%' }}>SOS</TableHeaderCell>
          <TableHeaderCell style={{ width: '8%' }}>Proj.<br/>points</TableHeaderCell>
          <TableHeaderCell style={{ width: '7%' }}>Proj.<br/>PPG</TableHeaderCell>
          <TableHeaderCell style={{ width: '7%' }}>LY<br/>Rank</TableHeaderCell>
          <TableHeaderCell style={{ width: '8%' }}>LY<br/>Points</TableHeaderCell>
          <TableHeaderCell style={{ width: '7%' }}>LY<br/>PPG</TableHeaderCell>
        </TableHeaderRow>
      </thead>
      <tbody>
        {
          tiers.filter(tier => tier.players.some(player => !player.drafted && player.name.toLowerCase().indexOf(searchQuery) !== -1)).map(tier => {
            return (
              <>
            <TierLabelRow>
              <TierLabelCell colSpan={100}>Tier {tier.tierNumber}</TierLabelCell>
            </TierLabelRow>
            {
                tier.players.filter(player => !player.drafted && player.name.toLowerCase().indexOf(searchQuery) !== -1).map(player => (
                  <TableRow key={player.playerId} onClick={() => selectPlayer(player.playerId)}>
                    <TableRowCell style={{ width: '5%', textAlign: 'left' }} onClick={(e: MouseEvent<HTMLTableCellElement>) => handleQueueButtonClick(e, player)}>
                      <div style={{ background: player.queued ? DARK_MODE_CTA : 'transparent', borderRadius: '20px', textAlign: 'center', height: '28px', width: '28px', cursor: 'pointer' }}>
                      {
                        player.queued ? (
                            <TbPlaylistX style={{ height: '26px', width: '26px' }} />
                        ) : (
                          <TbPlaylistAdd style={{ height: '26px', width: '26px' }} />
                        )
                      }
                      </div>
                    </TableRowCell>
                    {
                      !hideDraftButton ? (
                        <TableRowCell style={{ width: '7%' }} $isLeftAlign
                        onClick={(e: MouseEvent<HTMLTableCellElement>) => handleDraftButtonClick(e, player.playerId)}>
                          <DraftButton 
                            $isUserPick={isUserPick}
                            >
                              { isUserPick ? 'Draft' : 'Mark' }
                          </DraftButton>
                        </TableRowCell>
                      ) : null
                    }
                    <TableRowCell style={{ width: '4%' }} $isLeftAlign>{player.position}{player.rank}</TableRowCell>
                    <TableRowCell style={{ width: '2%' }}>{tier.tierNumber}</TableRowCell>
                    <TableRowCell style={{ width: '4%' }}>{renderUserFlagIcon(player.userFlag)}</TableRowCell>
                    <TableRowCell style={{ width: '4%' }}>{renderTeamStatusIcon(player.teamStatus)}</TableRowCell>
                    <TableRowCell style={{ width: '3%' }}>
                      <div style={{ height: '8px', width: '8px', borderRadius: '5px', background: POSITION_INDICATOR_COLOR[player.position as keyof typeof POSITION_INDICATOR_COLOR], float: 'right', marginRight: '5px' }}></div>
                    </TableRowCell>
                    <TableRowCell $isLeftAlign>
                      <div style={{ fontWeight: 500 }}>{player.name}</div>
                      <div style={{ fontSize: '.6rem', color: DARK_MODE_TEXT_SECONDARY }}>{player.position} - {player.team}</div>
                    </TableRowCell>
                    <TableRowCell style={{ width: '7%' }}>{player.averageDraftPosition?.adp || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '7%' }}>{player.averageDraftPosition?.positionRank || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '5%' }}>{player.byeWeek}</TableRowCell>
                    <TableRowCell style={{ width: '5%' }}>{player.sos}</TableRowCell>
                    <TableRowCell style={{ width: '8%' }}>{player.projections?.fPts || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '7%' }}>{player.projections?.ppg || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '7%' }}>{player.previousYear?.positionRank || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '8%' }}>{player.previousYear?.fPts || '-'}</TableRowCell>
                    <TableRowCell style={{ width: '7%' }}>{player.previousYear?.ppg || '-'}</TableRowCell>
                  </TableRow>
                ))
              }
          </>)
          })
        }
        
      </tbody>
    </Table>
    </ScrollableContainer>
  )
}