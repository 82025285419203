import './App.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faDoorOpen,
  faFileUpload,
  faList,
  faListOl,
  faPencilAlt,
  faPlus,
  faSignInAlt,
  faSignOutAlt,
  faSquare,
  faTh,
  faTimes,
  faUserCheck,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Amplify } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Sidebar } from './app/components/sidebar/Sidebar';
import { DraftArena } from './app/views/arena/DraftArena';
import { DraftsList } from './app/views/draftsList/DraftsList';
import { Landing } from './app/views/landing/Landing';
import { UserRanks } from './app/views/userRanks/UserRanks';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { loginStateSelector } from './store/selectors/sessionSelector';
import { LoginState } from './store/slices/sessionSlice';
import { history } from './store/store';
import { getSessionThunk } from './store/thunk/sessionThunk';
import { CreateDraftIngress } from './app/views/createDraft/CreateDraftIngress';
import { CreateDraftFromScratch } from './app/views/createDraft/CreateDraftFromScratch';
import { CreateDraftFromSleeper } from './app/views/createDraft/CreateDraftFromSleeper';

// import awsconfig from './aws-exports';
Amplify.configure({
	Auth: {

		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		// identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

		// REQUIRED - Amazon Cognito Region
		region: 'us-east-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region 
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-1_IQjKmEwPr',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '5cutddgft07qmg5fnjpia0a16u',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Configuration for cookie storage
		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
		// cookieStorage: {
		// REQUIRED - Cookie domain (only required if cookieStorage is provided)
		// domain: '.yourdomain.com',
		// OPTIONAL - Cookie path
		// path: '/',
		// OPTIONAL - Cookie expiration in days
		// expires: 365,
		// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
		// sameSite: "strict" | "lax",
		// OPTIONAL - Cookie secure flag
		// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
		// secure: true
		// },


		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: 'USER_SRP_AUTH',

		// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
		// clientMetadata: { myCustomKey: 'myCustomValue' },

		// OPTIONAL - Hosted UI configuration
		// 		oauth: {
		// 				domain: 'your_cognito_domain',
		// 				scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
		// 				redirectSignIn: 'http://localhost:3000/',
		// 				redirectSignOut: 'http://localhost:3000/',
		// 				responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
		// 		}
	}
});

library.add(
	faBars,
	faList,
	faFileUpload,
	faSignInAlt,
	faListOl,
	faTh,
	faDoorOpen,
	faPlus,
	faPlusSquare,
	faSquare,
	faCheckSquare,
	faEdit,
	faPencilAlt,
	faChevronLeft,
	faChevronDown,
	faTimes,
	faSignOutAlt,
	faUserPlus,
	faUserCheck
)

const routes = [
	{
		path: '/login',
		main: (props: any) => <Landing {...props} />
	},
	{
		path: '/drafts/create/scratch',
		main: (props: any) => <CreateDraftFromScratch {...props} />
	},
	{
		path: '/drafts/create/sleeper',
		main: (props: any) => <CreateDraftFromSleeper {...props} />
	},
	{
		path: '/drafts/create',
		main: (props: any) => <CreateDraftIngress {...props} />
	},
	{
		path: '/drafts/:draftId',
		main: (props: any) => <DraftArena {...props} />
	},
	{
		path: '/drafts',
		main: (props: any) => <DraftsList {...props} />
	},
	{
		path: '/players',
		main: (props: any) => <UserRanks {...props} />
	},
	{
		path: '/',
		main: (props: any) => <Landing {...props} />
	}
];

function App() {
	const dispatch = useAppDispatch()
	const loginState = useAppSelector(loginStateSelector)

	useEffect(() => {
		dispatch(getSessionThunk())
	}, [dispatch])

	useEffect(() => {
		if (loginState === LoginState.IsLoggedIn) {
			console.log(history.location)
			if (!history.location.pathname.includes('drafts') && !history.location.pathname.includes('players')) {
				history.push('/drafts')
			}
		} else if (loginState === LoginState.IsNotLoggedIn) {
			history.push('/login')
		}
	}, [loginState])

	return (
		<div className="App">
			<ConnectedRouter history={history}>
				<Sidebar />
				<div className="content">
					<Switch>
						{routes.map(route => (
							<Route
								key={route.path}
								path={route.path}
								render={props => route.main(props)} />
						))}
						<Redirect from="/" to="/drafts" exact />
					</Switch>
				</div>
			</ConnectedRouter>
		</div>
	)
}

export default App
