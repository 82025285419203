import { PlayerV2 } from './player'

export type UserDraft = {
  draftId: string
  draftDateTime: string
  draftName: string
}

export type DraftsPage = {
  drafts: UserDraft[]
  nextPageStart: string
}

export type PlayerCount = {
  quarterback: number
  runningback: number
  wideReceiver: number
  tightEnd: number
  flex: number
  superflex: number
  defense: number
  kicker: number
  bench: number
}

export type DraftConfig = {
  numDrafters: number
  playerCount: PlayerCount
  draftName?: string
  owner?: string
}

export type DraftPicks = {
  [pickNum: number]: number
}

export type DraftDetails = {
  picks: DraftPicks
  config: DraftConfig
}

export type JoinDraftRequest = {
  draftPosition: number
}

export type DraftOrder = {
  [draftPos: number]: string
}

export type DraftPick = {
  overall: number
  roundPick: number
  round: number
  player?: PlayerV2
}

export type TeamsWithPicks = {
  [teamId: string]: DraftPick[]
}

export enum DraftOrigin {
  AdjustTheRanks,
  Sleeper
}