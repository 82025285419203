export const DARK_MODE_BG = '#171827'
export const DARK_MODE_CTA = '#4B59FB'
export const DARK_MODE_BUTTON = '#1F2137'
export const DARK_MODE_BUTTON_ACTIVE = '#7579AB'
export const DARK_MODE_TERTIARY = '#2F3142'
export const DARK_MODE_TEXT_PRIMARY = 'rgba(255,255,255,0.87)'
export const DARK_MODE_TEXT_SECONDARY = 'rgba(255,255,255,0.7)'
export const DARK_MODE_DRAWER_ROW = '#33354D'
export const DARK_MODE_TAB = '#09DDDD'

export const QB_COLOR = '#E91E63'
export const RB_COLOR = '#28AE56'
export const WR_COLOR = '#4F3CC9'
export const TE_COLOR = '#EE8E48'
export const FLEX_COLOR = '#0FA2F4' // '#C93C3C'
export const BN_COLOR = '#4A4E8E' //'#545677'
export const K_COLOR = '#BD66FF'
export const DST_COLOR = '#8E3930'
export const OTC_CELL = '#7579AB'

export const POSITION_INDICATOR_COLOR = {
  'QB': QB_COLOR,
  'RB': RB_COLOR,
  'WR': WR_COLOR,
  'TE': TE_COLOR
}