import { FC } from 'react'
import { PositionButton } from './PositionButton'
import styled from 'styled-components'
import { PositionSelectionOption } from '../../../store/slices/config/draftDrawerRanksConfig'

type PositionSelectorProps = {
  options: PositionSelectionOption[]
  selectedPosition: string
  setSelectedPosition: (position: string) => void
}

const Container = styled.div`
  display: flex;
  column-gap: 4px;
  margin-bottom: 5px;
`

export const PositionSelector: FC<PositionSelectorProps> = ({
  options,
  selectedPosition,
  setSelectedPosition
}: PositionSelectorProps) => {

  return (
    <Container>
      {
        options.map(positionSelectionOption => (
          <PositionButton
            key={positionSelectionOption.displayValue}
            isActive={selectedPosition === positionSelectionOption.displayValue}
            onClick={() => setSelectedPosition(positionSelectionOption.displayValue)}
            position={positionSelectionOption.displayValue}></PositionButton>
        ))
      }
    </Container>
  )
}