import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UserDraft } from '../../../models/draft';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { draftsSelector } from '../../../store/selectors/entitySelector';
import { getDraftsThunk } from '../../../store/thunk/entityThunk';

export const DraftsList: FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [currentPageIndex] = useState(0)
  const drafts = useAppSelector(state => draftsSelector(state))

  const currentPage = drafts.length ? drafts[currentPageIndex] : undefined

  useEffect(() => {
    dispatch(getDraftsThunk({}))
  }, [dispatch])

  const goToDraft = (draftId: string) => {
    history.push(`/drafts/${draftId}`)
  }

  const createDraft = () => {
    history.push('/drafts/create')
  }

  const renderDraftsList = () => {
    if (!currentPage) return;
    return currentPage.drafts.map((draft: UserDraft) => {
      return (
        <div key={draft.draftId} className="draft-list-item" onClick={() => goToDraft(draft.draftId)}>
          <div className="left">
            <span>{draft.draftName}</span>
          </div>
          <div className="right">
            <span className="date">{moment(draft.draftDateTime).format('M/D/YY')}</span>
            <span className="time">{moment(draft.draftDateTime).format('h:mma')}</span>
          </div>
          <span></span>
        </div>
      );
    });
  }


  return (
    <div className="DraftsList">
      <div>
        <h1>My Drafts</h1>
        <button onClick={createDraft}>New Draft</button>
        <div className="list">
          {currentPage && currentPage.drafts && currentPage.drafts.length
            ? renderDraftsList()
            : (
              <div className="no-drafts">
                <p>You haven't started any drafts.</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}