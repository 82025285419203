import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserRanksState = {
  currentPosition: string
}

const initialState: UserRanksState = {
  currentPosition: 'OVR'
}

export const userRanksSlice = createSlice({
  name: 'userRanks',
  initialState,
  reducers: {
    setCurrentPosition: (state, action: PayloadAction<string>) => {
      state.currentPosition = action.payload
    }
  }
})

export const {
  setCurrentPosition
} = userRanksSlice.actions

export default userRanksSlice.reducer