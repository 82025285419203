import { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { PlayerPosition, PlayerV2, PopulatedTier, Positions, UserFlag } from '../../../models/player';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectedPlayerSelector } from '../../../store/selectors/entitySelector';
import { currentPositionSelector, ranksForCurrentPositionSelector } from '../../../store/selectors/userRanksSelector';
import { updateSelectedPlayerId } from '../../../store/slices/entitySlice';
import { setCurrentPosition } from '../../../store/slices/userRanksSlice';
import {
  addTierToRanksThunk,
  deleteTierThunk,
  getPlayersThunk,
  getRanksThunk,
  rankPlayerThunk,
  updatePlayerFlagThunk,
} from '../../../store/thunk/entityThunk';
import { PlayerModal } from '../../components/modal/PlayerModal';
import { GroupSelector } from '../../components/ranks/GroupSelector';
import { PlayerRow } from '../../components/ranks/PlayerRow';
import { TierBucket } from '../../components/userRanks/TierBucket';

export type VisiblePositions = Positions<boolean>

export type UserRanksState = {
  ranks: PopulatedTier[]
  unrankedPlayers: PlayerV2[]
}

export const UserRanks: FC = () => {
  const dispatch = useAppDispatch()
  const [positions, setPositions] = useState<VisiblePositions>({
    [PlayerPosition.OVR]: true,
    [PlayerPosition.QB]: false,
    [PlayerPosition.RB]: false,
    [PlayerPosition.WR]: false,
    [PlayerPosition.TE]: false,
  })
  const {
    ranks: rankings,
    unrankedPlayers
  } = useAppSelector(ranksForCurrentPositionSelector)
  const currentPosition = useAppSelector(currentPositionSelector)
  const selectedPlayer = useAppSelector(selectedPlayerSelector)

  const insertTier = (insertAfter: number) =>
    dispatch(addTierToRanksThunk({ position: currentPosition, tierIndex: insertAfter }))

  const deleteTier = (tierId: string) =>
    dispatch(deleteTierThunk({ position: currentPosition, tierId }))

  const flagPlayer = (playerId: number, flag: UserFlag) =>
    dispatch(updatePlayerFlagThunk({
      position: currentPosition,
      playerId,
      flag
    }))

  const onDragEnd = (result: DropResult) => {
    if (result && result.destination) {
      dispatch(rankPlayerThunk({
        position: currentPosition,
        playerId: +result.draggableId,
        oldTierId: result.source.droppableId as string | 'unranked',
        newTierId: result.destination.droppableId as string | 'unranked',
        newIndexWithinTier: result.destination.index,
      }))
    }
  }

  useEffect(() => {
    dispatch(getPlayersThunk({}))
  }, [dispatch])

  useEffect(() => {
    dispatch(getRanksThunk({}))
  }, [dispatch])

  const renderGroups = () => {
    if (!rankings) return

    return rankings.map(tier => (
      <Droppable key={tier.tierNumber} droppableId={tier.tierId}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}>
            <TierBucket
              key={tier.tierNumber}
              tier={tier}
              flagPlayer={flagPlayer}
              insertTier={insertTier}
              deleteTier={deleteTier}
              onPlayerClick={selectPlayer}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ))
  }

  const onPositionChange = (position: PlayerPosition) => {
    setPositions({
      ...positions,
      [currentPosition]: false,
      [position]: true
    })
    dispatch(setCurrentPosition(position))
  }

  const selectPlayer = (playerId: number) => dispatch(updateSelectedPlayerId(playerId))
  const onModalClose = () => dispatch(updateSelectedPlayerId(undefined))

  return (
    <div className="UserRanks">
      <PlayerModal player={selectedPlayer} onClose={onModalClose} />
      <header>
        <GroupSelector groupVisibility={positions} togglePositionVisible={onPositionChange}></GroupSelector>
      </header>
      <div className="ranks">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="tiers">
            {renderGroups()}
          </div>
          {
            !!unrankedPlayers.length && (
              <div className="unranked-players">
                <header>
                  <h3>Unranked players</h3>
                </header>
                <Droppable droppableId='unranked'>
                  {
                    provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        <div className="player-list">
                          {
                            unrankedPlayers.map((player, index) => (
                              <Draggable
                                key={player.playerId}
                                draggableId={player.playerId.toString()}
                                index={index}
                              >
                                {
                                  provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <PlayerRow key={player.playerId} player={player} />
                                    </div>
                                  )
                                }
                              </Draggable>
                            ))
                          }
                        </div>
                        {provided.placeholder}
                      </div>
                    )
                  }
                </Droppable>
              </div>
            )
          }
        </DragDropContext>
      </div>
    </div>
  )
}