import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

import { PlayerV2, UserFlag } from '../../../models/player';
import { UserFlagWidget } from './UserRankWidget';

type PlayerRowProps = {
  player: PlayerV2
  isUserPick?: boolean
  hideDraftedPlayers?: boolean
  draftPlayer?: (playerId: number) => void
  flagPlayer?: (playerId: number, flag: UserFlag) => void
  queuePlayer?: (playerId: number) => void
  dequeuePlayer?: (playerId: number) => void
  onPlayerClick?: (playerId: number) => void
}

export const PlayerRow: FC<PlayerRowProps> = ({
  player,
  isUserPick,
  draftPlayer,
  flagPlayer,
  hideDraftedPlayers,
  queuePlayer,
  dequeuePlayer,
  onPlayerClick,
}) => {

  const handleDraftButtonClick = ($event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    $event.stopPropagation()
    if (draftPlayer)
      draftPlayer(player.playerId)
  }

  const handleQueueButtonClick = ($event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    $event.stopPropagation()
    if (!queuePlayer || !dequeuePlayer)
      return

    !player.queued ? queuePlayer(player.playerId) : dequeuePlayer(player.playerId)
  }

  const renderActionButton = () => {
    if (!!draftPlayer)
      return (
        <>
          <button
            className={'queue-button' + (player.queued ? ' queued' : '')}
            onClick={($event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleQueueButtonClick($event)}>
            <FontAwesomeIcon icon={player.queued ? 'user-check' : 'user-plus'} />
          </button>
          <button
            className={`draft-button ${isUserPick ? 'is-user-pick' : ''}`}
            onClick={($event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDraftButtonClick($event)}
            disabled={player.drafted}>
            Draft
          </button>
        </>
      )
    if (!!flagPlayer)
      return (
        <UserFlagWidget
          player={player}
          flagPlayer={flagPlayer}
          size="lg" />
      )
  }

  const handlePlayerClick = () => {
    if (onPlayerClick) {
      onPlayerClick(player.playerId)
    }
  }

  return (
    <div className={`
      player-row 
      ${!!draftPlayer && player.drafted ? 'drafted' : ''}
      ${hideDraftedPlayers ? 'hide-drafted' : ''}
      flag-${player.userFlag}
      ${!!onPlayerClick ? 'clickable ' : ''} +
      `}
      hidden={hideDraftedPlayers && player.drafted}
      onClick={handlePlayerClick}
    >
      {
        !!player.rank ? (
          <span className="player-rank-num">
            {player.rank}
          </span>
        ) : (
          <img src={player.photoUrl} alt="Headshot" />
        )
      }
      <div className={`player-name `}>
        <div className="name">{player.name}</div>
        <div className="player-team-info">
          <span>{player.position} - {player.team} - ADP {player.adp}</span>
        </div>
      </div>
      {renderActionButton()}
    </div>
  )
}