import { PlayerV2 } from './models/player';

export const KICKER_ID: number = 888888
export const DEFENSE_ID: number = 999999
export const KICKER_AS_PLAYER: PlayerV2 = {
  adp: 0,
  age: 0,
  byeWeek: 0,
  fantasyPosition: 'K',
  firstName: 'Kicker',
  lastName: '',
  name: '',
  number: 0,
  photoUrl: '',
  playerId: KICKER_ID,
  position: 'K',
  team: 'NFL',
  sos: 0,
}

export const DEFENSE_AS_PLAYER: PlayerV2 = {
  adp: 0,
  age: 0,
  byeWeek: 0,
  fantasyPosition: 'DST',
  firstName: 'Defense',
  lastName: '',
  name: '',
  number: 0,
  photoUrl: '',
  playerId: DEFENSE_ID,
  position: 'DST',
  team: 'NFL',
  sos: 0
}

export const SLEEPER_TO_FANTASY_DATA_PLAYER_ID: { [playerId: string ]: number } = {
  6794:	21685,
  4034:	18877,
  7564:	22564,
  4663:	19562,
  1466:	15048,
  4039:	18882,
  3321:	18082,
  9509:	23189,
  2449:	16906,
  4866:	19766,
  6813:	21682,
  6786:	21679,
  5859:	21042,
  4046:	18890,
  2133:	16470,
  4988:	19798,
  7547:	22587,
  3198:	17959,
  8146:	23122,
  5850:	20824,
  7526:	22598,
  4984:	19801,
  6904:	21831,
  5967:	20912,
  5012:	19803,
  8155:	22526,
  7611:	22546,
  8144:	22565,
  6801:	21690,
  7528:	21768,
  7525:	21687,
  7543:	21696,
  5846:	20875,
  6770:	21693,
  9221:	23200,
  4881:	19781,
  8151:	23214,
  5872:	20932,
  1479:	15076,
  4199:	19045,
  4018:	18858,
  5844:	20805,
  4981:	19802,
  2309:	16765,
  7591:	22492,
  4217:	19063,
  6797:	21681,
  1426:	14986,
  5927:	20873,
  6806:	21674,
  8129:	23223,
  4983:	19844,
  6151:	20933,
  8112:	23151,
  6783:	21692,
  8167:	23395,
  4037:	18880,
  7523:	22490,
  7553:	22508,
  6790:	21684,
  5987:	20868,
  4029:	18872,
  5022:	19863,
  6938:	21688,
  4137:	18983,
  6819:	21744,
  2505:	16964,
  5848:	21045,
  6803:	21747,
  4068:	18914,
  2374:	16830,
  8136:	23217,
  4035:	18878,
  4950:	19815,
  8205:	23371,
  7588:	22558,
  5937:	21077,
  2216:	16597,
  9488:	23157,
  5892:	20882,
  8137:	23153,
  8135:	23119,
  8119:	23155,
  9756:	23162,
  7600:	22507,
  3294:	18055,
  4066:	18912,
  8138:	23247,
  7606:	22614,
  4017:	18857,
  6828:	21802,
  4033:	18876,
  6945:	21861,
  2197:	16568,
  6768:	21677,
  9754:	24226,
  3199:	17960,
  4040:	18883,
  8154:	23190,
  6943:	21735,
  4985:	19799,
  4149:	18995,
  9229:	24117,
  9753:	23173,
  4147:	18993,
  9997:	23120,
  5001:	19920,
  7608:	22563,
  1166:	14252,
  5045:	19800,
  7596:	22592,
  7571:	22623,
  96:	2593,
  9226:	24179,
  2078:	16389,
  8148:	23234,
  5890:	20790,
  6826:	21772,
  8210:	23489,
  5185:	20145,
  10236:	24102,
  5870:	20841,
  2161:	16510,
  1373:	14895,
  1689:	15534,
  7561:	22535,
  5947:	20876,
  8172:	23310,
  3163:	17922,
  8132:	23250,
  7601:	22626,
  7090:	21961,
  3225:	17986,
  3271:	18032,
  1234:	14536,
  6130:	20941,
  10225:	23161,
  9757:	24238,
  4111:	18957,
  8168:	23168,
  10229:	24120,
  10859:	23141,
  9228:	23132,
  8121:	23167,
  3164: 17923,
  4089:	18935,
  10235:	24372,
  5849:	20889,
  2749:	17217,
  9225:	23193,
  7569:	21756,
  4080:	18926,
  9482:	23140,
  4666:	19565,
  8228:	23479,
  5038:	19867,
  2028:	16311,
  6804:	21841,
  6126:	20949,
  5284:	20360,
  7002:	21738,
  4951:	19816,
  421:	9038,
  9497:	24125,
  8676:	23729,
  9758:	23239,
  5906:	20850,
  9224:	24374,
  8143:	23205,
  7066:	21714,
  9508:	24123,
  4993:	19853,
  10222: 23233,
  6820:	21769,
  4381:	19238,
  8160:	22501,
  6853:	21739,
  8147:	23160,
  8142:	23232,
  6824:	21754,
  7594:	21691,
  5955:	20924,
  3969:	18803,
  1339:	14856,
  4098:	18944,
  2747:	17215,
  6083:	20769,
  1433:	14996,
  8183:	23241,
  4082:	18928,
  5880:	21005,
  4973:	19843,
  8126:	23170,
  9494:	23165,
  5248:	20239,
  1535:	15150,
  6920:	21760,
  8130:	23251,
  1352:	14871,
  7567:	22562,
  9500:	24145,
  8162:	23185,
  5086:	19976,
  8230:	21852,
  9481:	24150,
  7610:	22493,
  7527: 22496,
  4892:	19790,
  1049:	13799,
  1837:	16041,
  1264:	14688,
  7839:	22908,
  5095:	19989,
  4227:	19073,
  7042:	22108,
  4195:	19041,
  9227: 24178,
  9502:	24176,
  9512:	24212,
  8131:	23256,
  4351:	19207,
  6886:	21752,
  8159:	22502,
  8116:	23218,
  5133:	19988,
  8139:	22539,
  5857:	20753,
  9222:	23174,
  8188:	23469,
  8134:	23227,
  8110:	23138,
  9505:	24188,
  9493:	24172,
  7607:	22553,
  8111:	23134,
  8219:	23490,
  6149:	20943,
  5917:	20788,
  1992:	16263,
  8140:	23163,
  5119:	20033,
  8259:	23567,
  2319:	16775,
  2251:	16656,
  7565:	22685,
  10223: 23195,
  8800:	23880,
  10232: 24173,
  3214:	17975,
  1067:	13870,
  8221:	22560,
  7045:	21776,
  6144:	20990,
  7670:	22613,
  5970:	21071,
  9479:	23136,
  4171:	19017,
  5137:	20077,
  503: 10333,
  8258: 23304, 
  10937: 24423,
  1266: 14697, 
  5272: 20325, 
  11533: 24902,
  7922: 22863, 
  3678: 18478, 
  11008: 24371,
  11145: 24741,
  5189: 20149, 
  8536: 23652, 
  2020: 16301, 
  17: 549,
  3451: 18215, 
  4958: 15854, 
  10955: 24436,
  6528: 21519, 
  7062: 22112, 
  1945: 16191,
  11628: 24974,
  11632: 24953,
  11620: 24977,
  11624: 24924,
  11604: 24943,
  11635: 25097,
  11583: 24944,
  11631: 24933,
  11637: 24964,
  11566: 23235,
  11586: 24116,
  11625: 24954,
  11626: 25003,
  11643: 24923,
  11596: 24985,
  11581: 23211,
  11638: 24982,
  11619: 24952,
  11630: 25039,
  9486: 23253,
  11647: 25079,
  11565: 24956,
  11655: 25092,
  11575: 24980,
  11640: 23158,
  11564: 24958,
  11576: 24934,
  6845: 21784,
  11589: 24292,
  7021: 21952,
  11560: 24927,
  11439: 24311,
  9501: 24180,
  8150: 23212,
  11584: 24967,
  11618: 25098,
  11645: 25040,
  11617: 24979,
  9999: 23270,
  11579: 24971,
  3286: 18047,
  9511: 24214,
  8408: 23364,
  "ARI": 1,
  "ATL": 2,
  "BAL": 36,
  "BUF": 37,
  "CAR": 5,
  "CHI": 38,
  "CIN": 7,
  "CLE": 8,
  "DAL": 48,
  "DEN": 49,
  "DET": 11,
  "GB": 12,
  "HOU": 13,
  "IND": 14,
  "JAX": 52,
  "KC": 55,
  "LAC": 84,
  "LAR": 32,
  "LV": 83,
  "MIA": 64,
  "MIN": 67,
  "NE":	21,
  "NO": 69,
  "NYG": 75,
  "NYJ": 82,
  "PHI":	26,
  "PIT": 28,
  "SEA": 90,
  "SF":	93,
  "TB": 94,
  "TEN": 34,
  "WAS": 97
}