import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { saveRanks } from '../../clients/proxyClient';
import { DEFENSE_AS_PLAYER, DEFENSE_ID, KICKER_AS_PLAYER, KICKER_ID, SLEEPER_TO_FANTASY_DATA_PLAYER_ID } from '../../constant';
import { KeyedMap, NumberedMap } from '../../models/common';
import { DraftsPage } from '../../models/draft';
import { PlayerPosition, PlayerV2, Positions, Tier, UserFlags } from '../../models/player';
import { RankItem, RanksByPosition } from '../../models/ranks';
import { DeleteTierPayload, InsertTierPayload, SaveRanksPayload, UpdatePlayerRankPayload } from '../models/entityActions';
import { RootState } from '../store';
import { getDraftDetailsThunk, getSleeperDraftDetailsThunk, getSleeperDraftPicksThunk } from '../thunk/draftArenaThunk';
import {
  addTierToRanksThunk,
  deleteTierThunk,
  getDraftsThunk,
  getPlayerDetailThunk,
  getPlayersThunk,
  GetPlayersThunkFulfilledPayload,
  getRanksThunk,
  GetRanksThunkFulfilledPayload,
  rankPlayerThunk,
  updatePlayerDetailThunk,
  updatePlayerFlagThunk,
} from '../thunk/entityThunk';
import { logoutUserThunk } from '../thunk/sessionThunk';
import { deleteDraftPickThunk, dequeuePlayer, draftPlayerThunk, queuePlayer, unloadDraft } from './draftArenaSlice';

type EntityState = {
  playersByPlayerId: KeyedMap<PlayerV2>
  playersByPosition: KeyedMap<Array<string>>
  drafts: DraftsPage[]
  ranks: RanksByPosition
  flags: UserFlags
  tiers: Positions<NumberedMap<Tier>>
  draftedPlayers: KeyedMap<boolean>
  queuedPlayers: KeyedMap<boolean>
  selectedPlayerId?: number
}

const initialState: EntityState = {
  playersByPlayerId: {
  },
  playersByPosition: {},
  drafts: [],
  ranks: {},
  flags: {},
  tiers: {
    QB: {},
    RB: {},
    WR: {},
    TE: {},
    OVR: {},
  },
  draftedPlayers: {},
  queuedPlayers: {},
}

const tiersToRankItems = (tiers: NumberedMap<Tier>, position: PlayerPosition) => {
  let rank = 1
  return Object.keys(tiers)
    .map(tierNumber => tiers[+tierNumber])
    .sort((a, b) => a.tierNumber - b.tierNumber)
    .map(tier =>
      tier.players.map(playerKey => ({
        key: playerKey,
        rank: rank++,
        tier: tier.tierNumber,
        position
      } as RankItem))
    )
    .flat()
}

export const saveRanksThunk: any = createAsyncThunk(
  'saveRanks',
  async ({ position }: SaveRanksPayload, { getState }) => {
    const entityState = (getState() as RootState).entity
    const ranks = tiersToRankItems(entityState.tiers[position], position)
    await saveRanks(position, { ranks })
  }
)

export const entitySlice = createSlice({
  name: 'entitySlice',
  initialState,
  reducers: {
    insertTier: (state, action: PayloadAction<InsertTierPayload>) => {
      const { position, insertAfter } = action.payload
      const tiers = state.tiers[position]

      const newTiers = Object.keys(tiers).reduce((acc, curr) => {
        const currTierKey = parseInt(curr, 10)
        if (currTierKey <= insertAfter) {
          return {
            ...acc,
            [currTierKey]: tiers[currTierKey]
          }
        }
        return {
          ...acc,
          [currTierKey + 1]: {
            ...tiers[currTierKey],
            tierNumber: currTierKey + 1,
          },
        }
      }, {
        [insertAfter + 1]: {
          players: [],
          tierNumber: insertAfter + 1,
        }
      } as NumberedMap<Tier>)
      state.tiers[position] = newTiers
    },
    deleteTier: (state, action: PayloadAction<DeleteTierPayload>) => {
      const { position, tierNumber } = action.payload
      const tiers = state.tiers[position]

      const newTiers = Object.keys(tiers).reduce((acc, curr) => {
        const currTierKey = parseInt(curr, 10)
        if (currTierKey < tierNumber) {
          return {
            ...acc,
            [currTierKey]: tiers[currTierKey]
          }
        }
        if (currTierKey > tierNumber) {
          return {
            ...acc,
            [currTierKey - 1]: {
              ...tiers[currTierKey],
              tierNumber: currTierKey - 1,
            },
          }
        }
        return acc
      }, {} as NumberedMap<Tier>)
      state.tiers[position] = newTiers
    },
    updatePlayerRank: (state, action: PayloadAction<UpdatePlayerRankPayload>) => {
      const { position, startTier, endTier, startIndex, endIndex, playerId } = action.payload
      const tiers = state.ranks[position].tiers
      if (startTier !== 'unranked' && endTier !== 'unranked') {
        if (startTier === endTier) {
          const [removed] = tiers[startTier].splice(startIndex, 1)
          tiers[startTier].splice(endIndex, 0, removed)
        } else {
          const [removed] = tiers[startTier].splice(startIndex, 1)
          tiers[endTier].splice(endIndex, 0, removed)
          tiers[startTier].splice(startIndex, 0)
        }
      } else {
        if (startTier === 'unranked' && endTier !== 'unranked') {
          tiers[endTier].splice(endIndex, 0, playerId)
        }
      }
    },
    updateSelectedPlayerId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPlayerId = action.payload
    }
  },
  extraReducers: reducer =>
    reducer
      .addCase(getDraftsThunk.fulfilled, (state, action) => {
        if (!action.meta.arg.start) {
          state.drafts = [action.payload]
        } else {
          state.drafts.push(action.payload)
        }
      })
      .addCase(getPlayersThunk.fulfilled, (state, action: PayloadAction<GetPlayersThunkFulfilledPayload>) => {
        const playersByPosition = action.payload.players
        const flattenedPlayersObject = Object.keys(playersByPosition)
          .map(position => {
            state.playersByPosition[position] = Object.keys(playersByPosition[position])
            return playersByPosition[position]
          })
          .reduce((acc: KeyedMap<PlayerV2>, curr: KeyedMap<PlayerV2>) => ({
            ...acc,
            ...curr
          }), {})
        state.playersByPlayerId = {
          ...state.playersByPlayerId,
          ...flattenedPlayersObject,
          [KICKER_ID]: KICKER_AS_PLAYER,
          [DEFENSE_ID]: DEFENSE_AS_PLAYER
        }
      })
      .addCase(getPlayerDetailThunk.fulfilled, (state, action) => {
        const playerId = action.meta.arg
        state.playersByPlayerId[playerId].notes = action.payload.notes
      })
      .addCase(getRanksThunk.fulfilled, (state, action: PayloadAction<GetRanksThunkFulfilledPayload>) => {
        state.ranks = action.payload.ranks
        state.flags = {
          ...state.flags,
          ...action.payload.flags
        }
      })
      .addCase(updatePlayerFlagThunk.pending, (state, action) => {
        const { playerId, flag } = action.meta.arg
        state.flags[playerId] = flag
      })
      .addCase(draftPlayerThunk.pending, (state, action) => {
        const { playerId } = action.meta.arg
        state.draftedPlayers[playerId] = true
        delete state.queuedPlayers[playerId]
      })
      .addCase(getDraftDetailsThunk.pending, (state) => {
        state.draftedPlayers = {}
        state.queuedPlayers = {}
      })
      .addCase(getDraftDetailsThunk.fulfilled, (state, action) => {
        const { draftDetails } = action.payload
        const picks = draftDetails.picks
        Object.keys(picks).forEach(key => {
          const pickNumber = parseInt(key)
          state.draftedPlayers[picks[pickNumber]] = true
        })
      })
      .addCase(getSleeperDraftDetailsThunk.fulfilled, (state, action) => {
        const { draftDetails } = action.payload
        const picks = draftDetails.picks
        Object.keys(picks).forEach(key => {
          const pickNumber = parseInt(key)
          state.draftedPlayers[picks[pickNumber]] = true
        })
      })
      .addCase(getSleeperDraftPicksThunk.fulfilled, (state, action) => {
        action.payload.forEach(pick => {
          const domainPlayerId = SLEEPER_TO_FANTASY_DATA_PLAYER_ID[pick.player_id]
          state.draftedPlayers[domainPlayerId] = true
          delete state.queuedPlayers[domainPlayerId]
        })
      })
      .addCase(rankPlayerThunk.pending, (state, action) => {
        const {
          position,
          newTierId,
          newIndexWithinTier,
          oldTierId,
          playerId
        } = action.meta.arg
        const tiers = state.ranks[position].tiers
        let oldIndex 

        if (oldTierId !== 'unranked' && newTierId !== 'unranked') {
          oldIndex = tiers[oldTierId].findIndex(pId => pId.toString() === playerId.toString())
          if (oldTierId === newTierId) {
            const [removed] = tiers[oldTierId].splice(oldIndex, 1)
            tiers[oldTierId].splice(newIndexWithinTier, 0, removed)
          } else {
            const [removed] = tiers[oldTierId].splice(oldIndex, 1)
            tiers[newTierId].splice(newIndexWithinTier, 0, removed)
            tiers[oldTierId].splice(oldIndex, 0)
          }
        } else {
          if (oldTierId === 'unranked' && newTierId !== 'unranked') {
            tiers[newTierId].splice(newIndexWithinTier, 0, playerId)
          }
        }
        action.meta.arg.oldIndexWithinTier = oldIndex
      })
      .addCase(addTierToRanksThunk.pending, (state, action) => {
        const { tierIndex, position } = action.meta.arg
        const tierId = 'temp'
        state.ranks[position].tierOrder.splice(tierIndex, 0, tierId)
        state.ranks[position].tiers[tierId] = []
      })
      .addCase(addTierToRanksThunk.fulfilled, (state, action) => {
        const { tierIndex, position } = action.meta.arg
        const { tierId } = action.payload
        state.ranks[position].tierOrder[tierIndex] = tierId
        state.ranks[position].tiers[tierId] = state.ranks[position].tiers['temp']
        delete state.ranks[position].tiers['temp']
      })
      .addCase(deleteTierThunk.fulfilled, (state, action) => {
        const { tierId, position } = action.meta.arg
        state.ranks[position].tierOrder = state.ranks[position].tierOrder.filter(id => id !== tierId)
        delete state.ranks[position].tiers[tierId]
      })
      .addCase(saveRanksThunk.fulfilled, (state, action) => {
      })
      .addCase(queuePlayer, (state, action) => {
        const { playerId } = action.payload
        state.queuedPlayers[playerId] = true
      })
      .addCase(dequeuePlayer, (state, action) => {
        const { playerId } = action.payload
        delete state.queuedPlayers[playerId]
      })
      .addCase(unloadDraft, (state) => {
        state.queuedPlayers = {}
        state.draftedPlayers = {}
      })
      .addCase(deleteDraftPickThunk.fulfilled, (state, action) => {
        const { playerId } = action.payload
        delete state.draftedPlayers[playerId]
      })
      .addCase(updatePlayerDetailThunk.pending, (state, action) => {
        const { playerId, notes } = action.meta.arg
        state.playersByPlayerId[playerId].notes = notes
      })
      .addCase(logoutUserThunk.fulfilled, () => initialState)
})

export const {
  insertTier,
  deleteTier,
  updateSelectedPlayerId,
  updatePlayerRank
} = entitySlice.actions

export default entitySlice.reducer
