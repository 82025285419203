import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

import { PlayerV2, UserFlag } from '../../../models/player';

type UserFlagWidgetProps = {
  size: 'lg' | '3x'
  player: PlayerV2
  flagPlayer: (playerId: number, rank: UserFlag) => void
}

export const UserFlagWidget: FC<UserFlagWidgetProps> = ({
  size,
  player,
  flagPlayer
}) => {

  const handleRankingClick = (rank: UserFlag, $event: any) => {
    $event.stopPropagation()

    rank = rank === player.userFlag ? UserFlag.Unranked : rank
    flagPlayer(player.playerId, rank)
  }

  return (
    <div className="UserFlagWidget">
      <FontAwesomeIcon
        icon={player?.userFlag === UserFlag.AllIn ? "check-square" : "square"}
        size={size}
        className="all-in"
        onClick={($event) => handleRankingClick(UserFlag.AllIn, $event)} />
      <FontAwesomeIcon
        icon={player?.userFlag === UserFlag.Neutral ? "check-square" : "square"}
        size={size}
        className="neutral"
        onClick={($event) => handleRankingClick(UserFlag.Neutral, $event)} />
      <FontAwesomeIcon
        icon={player?.userFlag === UserFlag.AllOut ? "check-square" : "square"}
        size={size}
        className="all-out"
        onClick={($event) => handleRankingClick(UserFlag.AllOut, $event)} />
    </div>
  )
}