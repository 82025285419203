export const getCurrRoundPick = (overall: number, numDrafters: number) => {
  const mod = overall % numDrafters
  return mod === 0 ? numDrafters : mod
}

export const getCurrTeam = (overall: number, numDrafters: number) => {
  const currRound = Math.ceil(overall / numDrafters)
  const oddRound = currRound % 2 !== 0

  const mod = overall % numDrafters

  if (oddRound) {
    return mod === 0 ? numDrafters : mod
  } else {
    return mod === 0 ? 1 : (numDrafters - mod) + 1
  }
}

export const getNewRound = (newOverall: number, numTeams: number) => Math.ceil(newOverall / numTeams)

export const getPicksUntilNext = (
  userDraftSlot: number, 
  currentOverall: number,
  numTeams: number
): number => {
  const currRound = getNewRound(currentOverall, numTeams)
  const oddRound = currRound % 2 !== 0
  const currTeam = getCurrTeam(currentOverall, numTeams)

  if (currTeam === userDraftSlot) {
    return 0
  }

  if (oddRound) {
    // User already picked this round
    if (userDraftSlot < currTeam) {
      const picksRemainingInCurrentRound = numTeams - currTeam
      const numPicksInNextRound = numTeams - userDraftSlot
      return picksRemainingInCurrentRound + numPicksInNextRound
    }

    return userDraftSlot - currTeam
  } else {
    // User already picked this round
    if (currTeam < userDraftSlot) {
      return currTeam + userDraftSlot - 1
    }

    return currTeam - userDraftSlot
  }
}