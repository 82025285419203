import { FC } from 'react'
import styled from 'styled-components'
import { DARK_MODE_BUTTON, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY, OTC_CELL } from '../../../styles/variables'

type DraftRoundIndicatorProps = {
  round: number
  isCurrRound: boolean
}

const Container = styled.div<{ $isCurrRound: boolean }>`
  display: flex;
  background-color: ${ props => props.$isCurrRound ? OTC_CELL : DARK_MODE_BUTTON };
  height: 52px;
  width: 52px;
  border-radius: 4px;
  color: ${ props => props.$isCurrRound ? DARK_MODE_TEXT_PRIMARY : DARK_MODE_TEXT_SECONDARY };
  justify-content: center;
  align-items: center;
`

export const DraftRoundIndicator: FC<DraftRoundIndicatorProps> = ({
  round,
  isCurrRound,
}: DraftRoundIndicatorProps) => {
  return (
    <Container $isCurrRound={isCurrRound}>
      <span>{round}</span>
    </Container>
  )
}