import { FC } from 'react'
import { DraftOrder, DraftPick, TeamsWithPicks } from '../../../models/draft'
import { DraftPickCard } from './DraftPickCard'
import { PickState } from '../../../store/slices/draftArenaSlice'
import { DraftRoundIndicator } from './DraftRoundIndicator'

type DraftBoardProps = {
  teamsWithPicks: TeamsWithPicks
  teams: DraftOrder
  pickState: PickState
  userDraftSlot: number
  showClaimButton: boolean
  onClaimDraftSlotClick: (draftSlot: number) => void
  selectPlayer: (playerId: number) => void
}

export const DraftBoard: FC<DraftBoardProps> = ({
  teamsWithPicks,
  teams,
  pickState,
  userDraftSlot,
  showClaimButton,
  onClaimDraftSlotClick,
  selectPlayer,
}) => {
  const renderGrid = () => {
    return Object.keys(teamsWithPicks).map(key => {
      const draftSlot = parseInt(key, 10)
      return (
        <div className="team-column" key={draftSlot}>
          <div className="header">
            { 
              showClaimButton 
                && draftSlot !== userDraftSlot
                && <div className="claim-button" onClick={() => onClaimDraftSlotClick(draftSlot)}>Claim</div>
            }
            <h6>{teams[draftSlot]}</h6>
          </div>
          {renderTeamColumn(teamsWithPicks[draftSlot])}
        </div>
      )
    })
  }

  const renderRoundsColumn = () => {
    const randomTeam = Object.keys(teamsWithPicks)[0]

    if (!randomTeam) {
      return null
    }

    return (
      <div className="round-column">
        <div className="header"></div>
        {
          teamsWithPicks[randomTeam].map(pick => (
            <DraftRoundIndicator 
              key={pick.round}  
              round={pick.round} 
              isCurrRound={pickState.currRound === pick.round} 
              />
          ))
        }
      </div>
    )
  }

  const renderTeamColumn = (teamPicks: DraftPick[]) => {
    return teamPicks.map((pick: DraftPick) => {
      const { player, roundPick, round } = pick

      return <DraftPickCard 
        key={round}
        roundPick={roundPick}
        round={round}
        player={player}
        isCurrentPick={round === pickState.currRound && roundPick === pickState.roundPick}
        selectPlayer={selectPlayer}
        />
    })
  }

  return (
    <div className="DraftBoard">
      {renderRoundsColumn()}
      {renderGrid()}
    </div>
  )
}