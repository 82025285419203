import { DraftPick, PlayerCount } from '../models/draft'
import { RosterSlot } from '../models/roster'

export const FLEX_POSITIONS = ['RB', 'WR', 'TE']

export const RosterSlotDisplayValue: Record<keyof PlayerCount, string> = {
  quarterback: 'QB',
  runningback: 'RB',
  wideReceiver: 'WR',
  tightEnd: 'TE',
  flex: 'FLX',
  superflex: 'SF',
  defense: 'DST',
  kicker: 'K',
  bench: 'BN'
}

export const RosterSlotDisplayOrder: Record<keyof PlayerCount, number> = {
  quarterback: 1,
  runningback: 2,
  wideReceiver: 3,
  tightEnd: 4,
  flex: 5,
  superflex: 6,
  defense: 7,
  kicker: 8,
  bench: 9
}

export const findSlotIndexToUpdate = (roster: RosterSlot[], pick: DraftPick): number => {
  // Try to find open slot that matches player position
  let slotIndexToUpdate = roster.findIndex(slot => slot.slot === pick.player?.position && !slot.pick)

  if (slotIndexToUpdate !== -1) {
    return slotIndexToUpdate
  }

  if (FLEX_POSITIONS.includes(pick.player?.position as string)) {
    slotIndexToUpdate = roster.findIndex(slot => slot.slot === 'FLX' && !slot.pick)

    if (slotIndexToUpdate !== -1) {
      return slotIndexToUpdate
    }
  }
  return roster.findIndex(slot => slot.slot === 'BN' && !slot.pick)
}