import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import Modal from 'react-modal';

import { PlayerV2, UserFlag } from '../../../models/player';
import { useAppDispatch } from '../../../store/hooks';
import { getPlayerDetailThunk, updatePlayerDetailThunk, updatePlayerFlagThunk } from '../../../store/thunk/entityThunk';
import { UserFlagWidget } from '../ranks/UserRankWidget';

Modal.setAppElement('#root')

type PlayerModalProps = {
  player?: PlayerV2
  onClose: () => void
}

const customStyles = {
  overlay: {
    zIndex: 15
  },
  content: {
    padding: 0,
    inset: '25px',
    maxWidth: '660px',
    maxHeight: '720px',
    left: 0,
    right: 0,
    margin: '0 auto'
  },
};

export const PlayerModal: FC<PlayerModalProps> = ({
  player,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const [notes, setNotes] = useState<string | undefined>('')

  const flagPlayer = (playerId: number, flag: UserFlag) => {
    if (!player) {
      return
    }

    dispatch(updatePlayerFlagThunk({
      position: player.position,
      playerId,
      flag
    }))
  }

  const savePlayerDetail = () => {
    if (player && notes) {
      dispatch(updatePlayerDetailThunk({
        playerId: player?.playerId,
        notes
      }))
    }
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  }

  useEffect(() => {
    if (!player?.playerId) {
      return
    }
    dispatch(getPlayerDetailThunk(player.playerId))

  }, [dispatch, player?.playerId])

  useEffect(() => {
    setNotes(player?.notes)
  }, [player?.notes])

  return (
    <Modal
      isOpen={!!player}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      style={customStyles}
    >
      <div className="Player">
        <FontAwesomeIcon icon="times" className="icon" size="lg" onClick={onClose} />
        <header>
          {/* {smallScreen && <FontAwesomeIcon icon="chevron-left" className="icon" onClick={goBack} />} */}
          <img src={player?.photoUrl} alt="Player Headshot" />
          <div className="name-position">
            <h3 className="name">{player?.name}</h3>
            <h4 className="position">{player?.position} - {player?.team} - #{player?.number}</h4>
          </div>
        </header>
        <div className="info-cells">
          <div className="cell">
            <span className="cell-header">Age</span>
            <span className="cell-data">{player?.age}</span>
          </div>
          <div className="cell">
            <span className="cell-header">ADP</span>
            <span className="cell-data">{player?.adp}</span>
          </div>
          <div className="cell">
            <span className="cell-header">Bye</span>
            <span className="cell-data">{player?.byeWeek}</span>
          </div>
        </div>
        <div className="my-rank">
          <h3>My Rank</h3>
          <UserFlagWidget player={player as PlayerV2} flagPlayer={flagPlayer} size="3x"></UserFlagWidget>
        </div>
        <h3>Notes</h3>
        <textarea placeholder="Record your notes about this player" onChange={handleChange} value={notes}></textarea>
        <button onClick={savePlayerDetail}>Save notes</button>
      </div>
    </Modal>
  )
}