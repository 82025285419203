import { KeyedMap } from './common';

export enum PlayerPosition {
  QB = 'QB',
  RB = 'RB',
  WR = 'WR',
  TE = 'TE',
  OVR = 'OVR'
}

export enum TeamStatus {
  None,
  // QB and skill player on the same team
  Stack,
  // Two skill players on the same team
  Double
}

// export type Player = {
//   key: string
//   name: string
//   team: string
//   bye: number
//   rank: number
//   points: number
//   risk: number
//   adp: string
//   tier: number
//   notes: string
//   position: PlayerPosition
//   earlySos: number
//   playoffSos: number
//   fullSos: number
//   // Frontend specific properties
//   userFlag?: UserFlag
//   drafted?: boolean
//   queued?: boolean
// }

export type Version = number | 'USER'
export type RankingsVersions = NullablePositions<Version>

export type Tier = {
  tierNumber: number
  players: string[]
}

export type PopulatedTier = {
  playerPosition: string
  tierNumber: number
  tierId: string
  players: PlayerV2[]
}


export type AllTiers = Positions<PopulatedTier[]>

export enum UserFlag {
  AllIn = "ALL_IN",
  Neutral = "NEUTRAL",
  AllOut = "ALL_OUT",
  Unranked = "UNRANKED"
}

export type UserFlags = {
  [playerId: string]: UserFlag
}

export type Positions<T> = {
  [key in PlayerPosition]: T
}

export type NullablePositions<T> = {
  [key in PlayerPosition]: T | null
}

export type Projection = {
  fPts: number
  gamesPlayed: number
  name: string
  passAtt: number
  passComp: number
  passInt: number
  passTd: number
  passYd: number
  playerId: number
  position: string
  positionRank: string
  ppg: number
  recRec: number
  recTd: number
  recTgt: number
  recYd: number
  ruAtt: number
  ruTd: number
  ruYd: number
  team: string
}

export type PlayerIdToProjectionMap = { [playerId: string ]: Projection }

export type ProjectionsByPosition = {
  [position: string]: Array<Projection>
}

export type PreviousYearStats = {
  fPts: number
  gamesPlayed: number 
  playerId: number
  positionRank: string
  ppg: number
}

export type PlayerIdToPreviousYearStats = { [playerId: string]: PreviousYearStats }

export type PlayerAdp = {
  adp: number
  positionRank: string
}

export type PlayerIdToPlayerAdp = { [playerId: string]: PlayerAdp }

export type PlayerV2 = {
  playerId: number
  team: string
  number: number
  firstName: string
  lastName: string
  position: string
  fantasyPosition: string
  name: string
  age: number
  photoUrl: string
  byeWeek: number
  adp: number
  sos: number
  averageDraftPosition?: PlayerAdp
  projections?: Projection
  previousYear?: PreviousYearStats
  // Frontend specific properties
  userFlag?: UserFlag
  drafted?: boolean
  queued?: boolean
  rank?: number
  notes?: string
  teamStatus?: TeamStatus
}

export type PlayersByPosition = {
  [position: string]: KeyedMap<PlayerV2>
}
