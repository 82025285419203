import { createSlice } from '@reduxjs/toolkit';

import { getSessionThunk, loginUserThunk, logoutUserThunk, signUpUserThunk } from '../thunk/sessionThunk';

export enum LoginState {
  IsLoggedIn,
  IsNotLoggedIn,
  Unknown
}

export type SessionState = {
  loginState: LoginState
  loginPageErrorMessage?: string
}

const initialState: SessionState = {
  loginState: LoginState.Unknown
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: reducer =>
    reducer
      .addCase(loginUserThunk.fulfilled, (state) => {
        state.loginState = LoginState.IsLoggedIn
        state.loginPageErrorMessage = undefined
      })
      .addCase(loginUserThunk.rejected, (state, action) => {
        state.loginState = LoginState.IsNotLoggedIn
        state.loginPageErrorMessage = action.error.message
      })
      .addCase(signUpUserThunk.fulfilled, (state) => {
        state.loginState = LoginState.IsLoggedIn
        state.loginPageErrorMessage = undefined
      })
      .addCase(signUpUserThunk.rejected, (state, action) => {
        state.loginState = LoginState.IsNotLoggedIn
        state.loginPageErrorMessage = action.error.message
      })
      .addCase(logoutUserThunk.fulfilled, (state) => {
        state.loginState = LoginState.IsNotLoggedIn
        window.location.reload()
      })
      .addCase(getSessionThunk.fulfilled, (state) => {
        state.loginState = LoginState.IsLoggedIn
        state.loginPageErrorMessage = undefined
      })
      .addCase(getSessionThunk.rejected, (state) => {
        state.loginState = LoginState.IsNotLoggedIn
        state.loginPageErrorMessage = undefined
      })
})

export default sessionSlice.reducer