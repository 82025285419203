import { FC, MouseEvent } from 'react'
import { PlayerV2 } from '../../../../models/player'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { DARK_MODE_CTA, DARK_MODE_DRAWER_ROW, DARK_MODE_TERTIARY, DARK_MODE_TEXT_PRIMARY, DARK_MODE_TEXT_SECONDARY, POSITION_INDICATOR_COLOR } from '../../../../styles/variables'
import { TbPlaylistX } from 'react-icons/tb'
import { MdDragIndicator } from 'react-icons/md'

type QueueProps = {
  queue: PlayerV2[]
  isUserPick: boolean
  hideDraftButton?: boolean
  draftPlayer: (playerId: number) => void
  dequeuePlayer: (playerId: number) => void
  updateQueueOrder: (oldIndex: number, newIndex: number) => void
  selectPlayer: (playerId: number) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  color: ${DARK_MODE_TEXT_PRIMARY};
`

const EmptyQueue = styled.div`
  text-align: center;
  font-weight: 300;
  color: ${DARK_MODE_TEXT_SECONDARY};
  margin-top: 70px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableRow = styled.tr`
  background: ${DARK_MODE_DRAWER_ROW};
  height: 40px;
  border-bottom: 2px solid ${DARK_MODE_TERTIARY};
`

const TableRowCell = styled.td<{ $isLeftAlign?: boolean }>`
  color: ${DARK_MODE_TEXT_PRIMARY};
  vertical-align: middle;
  text-align: ${ props => props.$isLeftAlign ? 'left' : 'right' };
  font-size: .9rem;


  &:first-of-type {
    padding-left: 8px;
  }

  &:last-of-type {
    padding-right: 8px;
    padding-left: 15px;
  }
`

const TableHeaderCell = styled.th<{ $isLeftAlign?: boolean }>`
  color: ${DARK_MODE_TEXT_PRIMARY};
  font-size: .6rem;
  vertical-align: bottom;
  text-align: ${ props => props.$isLeftAlign ? 'left' : 'right' };

  &:first-of-type {
    padding-left: 8px;
  }

  &:last-of-type {
    padding-right: 8px;
    padding-left: 15px;
  }
`

const DraftButton = styled.button<{ $isUserPick: boolean }>`
  border: 1px solid ${DARK_MODE_CTA};
  background: ${ props => props.$isUserPick ? DARK_MODE_CTA : 'transparent' };
  color: ${DARK_MODE_TEXT_PRIMARY};
  width: 60px;
  height: 30px;
  font-size: .9rem;
`

export const Queue: FC<QueueProps> = ({
  queue,
  isUserPick,
  hideDraftButton,
  draftPlayer,
  dequeuePlayer,
  updateQueueOrder,
  selectPlayer,
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !updateQueueOrder)
      return

    updateQueueOrder(
      result.source.index,
      result.destination.index
    )
  }

  const handleQueueButtonClick = (e: MouseEvent<HTMLOrSVGElement>, playerId: number) => {
    e.stopPropagation()
    dequeuePlayer(playerId)
  }

  const handleDraftButtonClick = (e: MouseEvent<HTMLButtonElement>, playerId: number) => {
    e.stopPropagation()
    draftPlayer(playerId)
  }

  return (
    <Container>
      {!queue.length ? (
        <EmptyQueue>
          <span>Queue is empty</span>
        </EmptyQueue>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='queue'>
            { 
              provided => (
                <Table 
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  >
                    <thead>
                      <tr style={{ borderBottom: `4px solid ${DARK_MODE_TERTIARY}` }}>
                      <TableHeaderCell style={{ width: '10%', textAlign: 'left' }}></TableHeaderCell>
                      {
                        !hideDraftButton ? (
                          <TableHeaderCell style={{ width: '13%' }} $isLeftAlign>

                          </TableHeaderCell>
                        ) : null
                      }
                      <TableHeaderCell style={{ width: '7%' }}>Rank</TableHeaderCell>
                      <TableHeaderCell style={{ width: '7%' }}></TableHeaderCell>
                      <TableHeaderCell $isLeftAlign>Name</TableHeaderCell>
                      <TableHeaderCell style={{ width: '12%' }}>ADP</TableHeaderCell>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      queue.map((player, index) => (
                        <Draggable key={player.playerId} draggableId={player.playerId.toString()} index={index}>
                          {
                            provided => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => selectPlayer(player.playerId)}
                              >
                                <TableRowCell style={{ width: '10%', textAlign: 'left' }}>
                                  <div style={{ background: DARK_MODE_CTA, borderRadius: '20px', textAlign: 'center', height: '28px', width: '28px'}}>
                                    <TbPlaylistX style={{ height: '28px', width: '24px', cursor: 'pointer' }} onClick={(e: MouseEvent<HTMLOrSVGElement>) => handleQueueButtonClick(e, player.playerId) }></TbPlaylistX>
                                  </div>
                                </TableRowCell>
                                {
                                  !hideDraftButton ? (
                                    <TableRowCell style={{ width: '13%' }} $isLeftAlign>
                                      <DraftButton
                                        $isUserPick={isUserPick}
                                        onClick={(e: MouseEvent<HTMLButtonElement>) => handleDraftButtonClick(e, player.playerId)}
                                        >
                                          { isUserPick ? 'Draft' : 'Mark' }
                                      </DraftButton>
                                    </TableRowCell>
                                  ) : null
                                }
                                <TableRowCell style={{ width: '7%' }}>{player.position}{player.rank}</TableRowCell>
                                <TableRowCell style={{ width: '7%' }}>
                                  <div style={{ height: '8px', width: '8px', borderRadius: '5px', background: POSITION_INDICATOR_COLOR[player.position as keyof typeof POSITION_INDICATOR_COLOR], float: 'right', marginRight: '5px' }}></div>
                                </TableRowCell>
                                <TableRowCell $isLeftAlign>
                                  <div style={{ fontWeight: 500 }}>{player.name}</div>
                                  <div style={{ fontSize: '.6rem', color: DARK_MODE_TEXT_SECONDARY }}>{player.position} - {player.team}</div>
                                </TableRowCell>
                                <TableRowCell style={{ width: '12%' }}>{player.averageDraftPosition?.adp}</TableRowCell>
                                <TableRowCell style={{ width: '4%' }}>
                                  <MdDragIndicator style={{ height: '27px', width: '27px' }}></MdDragIndicator>
                                </TableRowCell>
                              </TableRow>
                            )
                          }
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                    </tbody>
                </Table>
              )
            }
          </Droppable>
        </DragDropContext>
        
      )
      }
    </Container>
  )
}