import { createSelector } from '@reduxjs/toolkit';

import { UserRanksState } from '../../app/views/userRanks/UserRanks';
import { PlayerPosition } from '../../models/player';
import { RootState } from '../store';
import { flagsSelector, playersByPositionSelector, playersSelector, ranksSelector } from './entitySelector';

const userRanksSelector = (state: RootState) => state.userRanks
export const currentPositionSelector = createSelector(userRanksSelector, state => state.currentPosition)
export const playersForCurrentPositionSelector = createSelector(
  currentPositionSelector,
  playersSelector,
  playersByPositionSelector,
  (currentPosition, players, playersByPosition) => {
    if (!Object.keys(players).length || !Object.keys(playersByPosition).length) return []

    let playersForCurrentPosition = []
    if (currentPosition === PlayerPosition.OVR) {
      playersForCurrentPosition = Object.values(playersByPosition).flatMap(players => players)
    } else {
      playersForCurrentPosition = playersByPosition[currentPosition]
    }
    return playersForCurrentPosition
      .map(playerId => players[playerId])
      .filter(player => player.position !== 'DST' && player.position !== 'K')
  }
)
export const ranksForCurrentPositionSelector = createSelector(
  currentPositionSelector,
  playersSelector,
  ranksSelector,
  flagsSelector,
  playersForCurrentPositionSelector,
  (currentPosition, players, ranks, flags, playersForCurrentPosition): UserRanksState => {
    if (!Object.keys(players).length || !Object.keys(ranks).length)
      return {
        ranks: [],
        unrankedPlayers: []
      }

    const ranksForCurrentPosition = ranks[currentPosition]
    if (!ranksForCurrentPosition || !Object.keys(ranksForCurrentPosition).length) {
      return {
        unrankedPlayers: playersForCurrentPosition,
        ranks: [{
          playerPosition: currentPosition as PlayerPosition,
          tierNumber: 1,
          tierId: 'temp',
          players: []
        }]
      }
    }

    const rankedPlayersSet = new Set()
    let numRank = 1
    const populatedRanks = ranksForCurrentPosition.tierOrder.map((tierId, ind) => {
      return {
        playerPosition: currentPosition as PlayerPosition,
        tierNumber: ind + 1,
        tierId,
        players: ranksForCurrentPosition.tiers[tierId].map(playerId => {
          rankedPlayersSet.add(playerId)
          return { ...players[playerId], rank: numRank++, userFlag: flags[playerId] }
        }).filter(player => player && !!player.playerId)
      }
    })
    return {
      unrankedPlayers: playersForCurrentPosition
        .filter(player => player && !!player.playerId && !rankedPlayersSet.has(player.playerId.toString()) && !rankedPlayersSet.has(player.playerId))
        .sort((a, b) => (a.averageDraftPosition?.adp || 9999999) - (b.averageDraftPosition?.adp || 99999999))
        .slice(0, 50),
      ranks: populatedRanks
    }
  }
)